import styled from 'styled-components';
import Plans from './index.component';

export default styled(Plans)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  gap: 2rem;

  .coupon {
    .input-container {
      width: 373px;
    }

    @media (max-width: 576px) {
      margin-bottom: 2rem;

      .input-container {
        width: 100%;
      }

      align-self: center;
    }
  }

  .loading {
    text-align: center;

    span {
      margin-left: 0.5rem;
    }
  }

  .error {
    text-align: center;

    .error-text {
      margin-bottom: 1rem;
    }
  }

  .plans {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 3rem;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      gap: 1rem;
      margin-top: 0;
    }
  }
`;

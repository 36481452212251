export interface Props extends StyledComponentProps {
  keywords: Keyword[];
  onChange: (keywords: Keyword[]) => void;
}

export interface Keyword {
  id: number;
  title: string;
}

export const KEYWORDS_SEPARATOR = ',';

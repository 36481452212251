import { CheckCircleFilled, TagFilled } from '@ant-design/icons';

import { Plan } from '../types';

export function useName(plan: Plan) {
  return plan.title.es;
}

export function getCheckIcon() {
  return <CheckCircleFilled className="check-icon" />;
}

export function getTagIcon() {
  return <TagFilled className="tag-icon" />;
}

export function getRegularPrice(plan: Plan) {
  const price = !!plan.coupon ? plan.priceWithDiscount : plan.price;

  return Intl.NumberFormat().format(
    Math.round(parseFloat(price) / parseFloat(plan.period)),
  );
}

export function getTotalPrice(plan: Plan) {
  const price = !!plan.coupon ? plan.priceWithDiscount : plan.price;

  return Intl.NumberFormat().format(parseFloat(price));
}

export function getDiscountRate(plan: Plan) {
  return plan.discountRate + '%';
}

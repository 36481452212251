import { css } from 'styled-components';

export const layoutWidth = css`
  margin: auto;
  width: calc(100% - 4rem);
  max-width: 1440px;

  @media (min-width: 800px) {
    width: calc(100% - 8rem);
  }
`;

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

// Own
import { DEFAULT_LANG as lang } from 'constants/env-variables';
import { Props } from './types';
import Price from './price.styled';
import { useName, getCheckIcon, getDiscountRate, getTagIcon } from './helpers';
import { useLogClickOnPayButton } from './use-log-click-pay-button';

const Card: FunctionComponent<Props> = ({ plan, className, loading }) => {
  const name = useName(plan);
  const { t } = useTranslation('plans');

  const logClickOnPayButton = useLogClickOnPayButton();

  const onClickPayButton = () => {
    logClickOnPayButton(plan.period);
  };

  const recommendedClass = plan.isRecommended ? ' recommended' : '';

  return (
    <div className={className + recommendedClass}>
      {plan.isRecommended && (
        <div className="recommended-header">
          {getTagIcon()}
          {t('youSave')} {getDiscountRate(plan)}
        </div>
      )}
      <div className="content">
        <h2 className="name">{name}</h2>

        <div className="features">
          {plan.features[lang].map(feature => (
            <div key={feature} className="feature">
              {getCheckIcon()}
              {'   '}
              <p className="feature-text">{feature}</p>
            </div>
          ))}
        </div>

        <Price className={recommendedClass} plan={plan} loading={loading} />

        <div className="btn-container">
          <Button
            type={plan.isRecommended ? 'primary' : 'default'}
            size="large"
            block
            href={plan.paymentLink}
            onClick={onClickPayButton}
            target="_blank"
          >
            {t(`goToPay.${plan.isRecommended ? 'recommended' : 'default'}`)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Card;

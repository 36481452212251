import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Row } from 'antd';

//Own
import Loading from 'components/loading-component';
import Error from 'components/error-component';
import Header from 'components/header';
import { useBusinesses } from './use-get-businesses';
import BusinessCard from './business-card';

const DashboardComponent: FunctionComponent<StyledComponentProps> = ({
  className,
}) => {
  const { businesses, loading, hasError } = useBusinesses();
  const { t } = useTranslation('dashboard');

  return (
    <div className={className}>
      <Header />
      {loading && <Loading />}
      {hasError && <Error />}

      <div className="business-list__container">
        <div className="title__text"> {t('title')}</div>
        <Row className="list__container">
          {businesses.map(x => (
            <BusinessCard data={x} key={x.uuid} />
          ))}
        </Row>
      </div>
    </div>
  );
};

export default styled(DashboardComponent)`
  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

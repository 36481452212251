import styled from 'styled-components';
import CouponInput from './index.component';

export default styled(CouponInput)`
  font-size: 1rem;

  .coupon-label {
    color: #000;
    font-weight: 500;
    margin-bottom: 0.5rem;

    .comment {
      color: #787878;
    }
  }

  .input-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .input {
      margin-right: 1rem;
    }
  }

  .feedback {
    margin-top: 0.5rem;

    > * {
      vertical-align: middle;
    }

    .icon {
      font-size: 1.5rem;
      margin-right: 0.2rem;
    }

    .icon-success {
      color: #31ca78;
    }

    .icon-error {
      color: #ff4749;
    }
  }
`;

import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormItemProps, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { UploadOutlined } from '@ant-design/icons';

// Own
import { getImageDimension } from './get-image-dimension';
import {
  Props,
  MAX_FILES,
  allowedImageMimeTypes,
  rawAllowedImageFormats,
} from './types';
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel';

const UploadImage: FunctionComponent<Props> = ({
  className,
  mockupClassName,
  dimensions,
  mockupImageSrc,
  mockupInfo,
  label,
  required,
  onRemove,
  onUpload,
}) => {
  const { t } = useTranslation('subscription-form');

  const [fileList, setFileList] = useState<RcFile[]>([]);

  const allowedImageFormatsText = rawAllowedImageFormats
    .map(x => x.toUpperCase())
    .join(` ${t('fileDescription.conector')} `);

  const onFileChange = async (file: RcFile) => {
    const isValidImageType = allowedImageMimeTypes.includes(file.type);

    if (!isValidImageType) return Upload.LIST_IGNORE;

    const dimension = await getImageDimension(file);

    if (dimension) {
      onUpload({ file, dimension });
      setFileList([file]);
    }

    return false;
  };

  const _onRemove = () => {
    onRemove();
    setFileList([]);
  };

  const requiredProps: Partial<FormItemLabelProps & FormItemProps> = required
    ? {
        requiredMark: true,
        required: true,
      }
    : {
        requiredMark: 'optional',
      };

  return (
    <div className={className}>
      <Form.Item label={label} {...requiredProps}>
        <div>
          <div className="upload-image--info">
            <p>{`${t('fileDescription.dimensions')} ${dimensions}`}</p>
            <p>{`${t('fileDescription.format')} ${allowedImageFormatsText}`}</p>
          </div>
          <Upload
            beforeUpload={onFileChange}
            onRemove={_onRemove}
            fileList={fileList}
            maxCount={MAX_FILES}
            accept={allowedImageMimeTypes.join(', ')}
          >
            <Button icon={<UploadOutlined />} type="primary">
              {t('button.uploadFile')}
            </Button>
          </Upload>
        </div>
      </Form.Item>

      {mockupImageSrc && (
        <div className={`${mockupClassName} upload-image--mockup-container`}>
          <img src={mockupImageSrc} alt="mockup" />
          <p className="upload-image--mockup-info">{mockupInfo}</p>
        </div>
      )}
    </div>
  );
};

export default UploadImage;

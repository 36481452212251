import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

//Own
import getMetricsRequest from 'services/metrics/get-metrics/get-metrics';
import { Data } from 'services/metrics/get-metrics/types';

export const useGetMetricData = (): Response => {
  const { businessUuid } = useParams<{ businessUuid: string }>();
  const shouldRequestData = useRef(true);

  const [data, setData] = useState<Data | null>(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const getMetrics = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getMetricsRequest({
        businessUuid: businessUuid + '',
        startDate: dayjs().subtract(3, 'months').toISOString(),
        endDate: dayjs().toISOString(),
        timezoneGMT: getTimezoneGMT(),
      });

      setData(data);
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [businessUuid]);

  useEffect(() => {
    if (shouldRequestData.current) getMetrics();

    shouldRequestData.current = false;
  }, [getMetrics]);

  return { data, loading, hasError };
};

function getTimezoneGMT(): string | null {
  return new Date().toString().match(/([A-Z]+[+-][0-9]+)/)?.[1] || null;
}

interface Response {
  data: Data | null;
  loading: boolean;
  hasError: boolean;
}

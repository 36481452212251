import { FunctionComponent } from 'react';
import Div100vh from 'react-div-100vh';

//Own
import RightSideComponent from './right-side-component';
import LeftSideComponent from './left-side-component';
import { ReactComponent as BalloonsIcon } from './balloons.svg';

const LoginComponent: FunctionComponent<StyledComponentProps> = ({
  className,
}) => {
  return (
    <Div100vh className={className}>
      <LeftSideComponent />
      <RightSideComponent />
      <BalloonsIcon className="balloons-icon__component" />
    </Div100vh>
  );
};

export default LoginComponent;

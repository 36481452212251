import { FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin, Button } from 'antd';

import useGetPlans, { useInitialPlans } from './use-get-plans';
import Card from './card';
import CouponInput from './coupon';
import useGlobalStyles from './use-global-styles';
import PromotionBanner from './promotion-banner';
import { SHOW_PROMOTION_BANNER } from 'constants/env-variables';
import { useSendDimensionsToParent } from './use-send-dimensions-to-parent';

const plansPricingContainerId = 'plans-pricing';

const Plans: FunctionComponent<StyledComponentProps> = ({ className }) => {
  useGlobalStyles();

  const { t } = useTranslation('plans');
  const [coupon, setCoupon] = useState(useInitialCoupon());
  const { plans, couponApplied, loading, error, loadPlans } = useGetPlans();
  useInitialPlans(loadPlans, coupon);

  const loadPlansWithCoupon = () => {
    loadPlans(coupon);
  };

  const showError = error && !plans.length;
  const showLoading = loading && !plans.length;
  // The error next to the coupon is shown only when there are plans.
  const errorForCoupon = plans.length ? error : false;

  useSendDimensionsToParent({
    elementId: plansPricingContainerId,
    shouldSend: !!plans.length,
  });

  return (
    <div className={className} id={plansPricingContainerId}>
      {SHOW_PROMOTION_BANNER && <PromotionBanner />}
      <CouponInput
        className="coupon"
        value={coupon}
        error={errorForCoupon}
        couponApplied={couponApplied}
        disable={loading}
        onChange={setCoupon}
        onSearch={loadPlansWithCoupon}
      />
      {showLoading && (
        <div className="loading">
          <Spin size="large" />
          <span>{t('loading')}</span>
        </div>
      )}
      {showError && (
        <div className="error">
          <div className="error-text">{t('errorLoading')}</div>
          <Button type="primary" onClick={loadPlansWithCoupon}>
            {t('reload')}
          </Button>
        </div>
      )}
      <div className="plans">
        {plans.map((plan, i) => (
          <Card className="plan" key={i} plan={plan} loading={loading} />
        ))}
      </div>
    </div>
  );
};

function useInitialCoupon() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return params.get('coupon') || '';
}

export default Plans;

import { FunctionComponent } from 'react';
import { Input, Button } from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ErrorType } from '../types';

const CouponInput: FunctionComponent<Props> = ({
  className,
  value,
  couponApplied,
  error,
  disable,
  onChange,
  onSearch,
}) => {
  const { t } = useTranslation('plans');
  const errorText = useErrorText(error);

  const handleSearch = () => {
    if (disable) return;

    onSearch();
  };

  return (
    <div className={className}>
      <div className="coupon-label">
        {t('couponLabel')}{' '}
        <span className="comment">{t('couponLabelComment')}</span>
      </div>
      <div className="input-container">
        <Input
          className="input"
          size="large"
          value={value}
          onChange={ev => {
            onChange(ev.target.value);
          }}
          onPressEnter={handleSearch}
        />
        <Button type="default" size="large" onClick={handleSearch}>
          {t('apply')}
        </Button>
      </div>
      {couponApplied && (
        <div className="feedback">
          <CheckCircleOutlined className="icon icon-success" />{' '}
          <span>{t('couponApplied')}</span>
        </div>
      )}
      {errorText && (
        <div className="feedback">
          <InfoCircleOutlined className="icon icon-error" />{' '}
          <span>{errorText}</span>
        </div>
      )}
    </div>
  );
};

function useErrorText(error: ErrorType | false) {
  const { t } = useTranslation('plans');

  if (error === ErrorType.InvalidCode) {
    return t('invalidCode');
  }

  if (error === ErrorType.Unknown) {
    return t('unknownError');
  }

  return '';
}

interface Props extends StyledComponentProps {
  value: string;
  couponApplied: boolean;
  error: ErrorType | false;
  disable?: boolean;
  onChange: (value: string) => void;
  onSearch: () => void;
}

export default CouponInput;

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

//Own
import ErrorPage from 'components/error-page';
import Subscription from 'core/subscription';
import Plans from 'core/plans';
import SubscriptionHeader from 'core/subscription/header';
import SubscriptionSuccess from 'core/subscription/success';
import Login from 'core/login';
import Terms from 'core/terms';
import Metrics from 'core/metrics';
import Dashboard from 'core/dashboard';
import ConfirmRegister from 'core/subscription/confirm-register/confirm-register-component';
import GuardLayout from 'core/guard-layout';
import AuthUser from 'core/auth-user/auth-user-component';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="subscription" element={<SubscriptionHeader />}>
        <Route index={true} element={<Subscription isGeolocated={true} />} />
        <Route path="success" element={<SubscriptionSuccess />} />
      </Route>

      <Route
        path="subscription/online-business"
        element={<SubscriptionHeader />}
      >
        <Route index={true} element={<Subscription isGeolocated={false} />} />
        <Route path="success" element={<SubscriptionSuccess />} />
      </Route>

      <Route
        path="/subscription/confirm-register/:tokenEmail"
        element={<ConfirmRegister />}
      />
      <Route path="/plans" element={<Plans />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/dashboard/metrics/:businessUuid" element={<Metrics />} />
      <Route path="/user/auth/:tokenEmail" element={<AuthUser />} />

      <Route element={<GuardLayout />}>
        <Route path="/" />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="login" element={<Login />} />
      </Route>

      {/* 👇️ only match this when no other routes match */}
      <Route path="*" element={<ErrorPage />} errorElement={<ErrorPage />} />
    </>,
  ),
);

export default router;

import styled from 'styled-components';
import DashboardComponent from './dashboard-component';

export default styled(DashboardComponent)`
  .business-list__container {
    padding: 16px 0px 16px 24px;
    height: 100%;
  }

  .title__text {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 20px;
  }

  .list__container {
    display: grid;
    gap: 1rem;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }

  .card__container {
    margin-bottom: 16px;
    margin-right: 16px;

    .card-item__container {
      margin-bottom: 16px;
      border-radius: 8px;
      border: 1px;
      border-style: solid;
      border-color: #dfdfdf;
      cursor: pointer;

      .card-image__container {
        height: 246px;
        display: flex;
        justify-content: center;
        align-items: center;

        .card__image {
          width: 200px;
          height: 200px;
        }
      }

      .card__divider {
        padding: 0px;
        margin: 0px;
      }

      .card-text__container {
        padding: 12px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .date__container {
      color: #787878;
      font-size: 14px;
      padding-bottom: 4px;

      .date__container-text::before {
        content: ' ';
      }

      .date-label__bold {
        font-weight: 500;
      }
    }
  }

  @media only screen and (max-width: 580px) {
    .business-list__container {
      padding: 16px 16px 16px 24px;
    }

    .card__container {
      margin-right: unset;
    }

    .header__container {
      padding: 24px 0px 24px 10px;
    }
  }
`;

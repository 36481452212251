import { useCallback, useEffect } from 'react';

export const useSendDimensionsToParent = ({ elementId, shouldSend }: Input) => {
  const getElementHeight = useCallback(() => {
    const element = document.querySelector(`#${elementId}`);

    if (!element) return 0;

    return Math.ceil(element.getBoundingClientRect().height);
  }, [elementId]);

  const sendReportHeightToWindowParent = useCallback(() => {
    const height = getElementHeight();

    if (!height) return;

    const dataMessage: DataMessage = {
      k: MESSAGE_KEY,
      height: height,
    };

    window.parent?.postMessage(dataMessage, '*');
  }, [getElementHeight]);

  useEffect(() => {
    if (shouldSend) {
      sendReportHeightToWindowParent();
    }
  }, [sendReportHeightToWindowParent, shouldSend]);
};

export interface Input {
  shouldSend: boolean;
  elementId: string;
}

export interface DataMessage {
  k: string;
  height: number;
}

export const MESSAGE_KEY = 'SOS-WIDGET-PLANS';

import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

// Own
import { ReactComponent as SosafeForBusinessLogo } from 'assets/sosafe-for-business.svg';

const SubscriptionHeader: FunctionComponent<StyledComponentProps> = ({
  className,
}) => {
  return (
    <>
      <header className={className}>
        <SosafeForBusinessLogo />
      </header>
      <Outlet />
    </>
  );
};

export default SubscriptionHeader;

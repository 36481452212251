import {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useRef,
} from 'react';
import { Marker } from '@react-google-maps/api';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import Map from 'core/map';
import { LoadMapContext } from 'core/map/load-map-context';
import { Coordinates, MapHandler } from 'core/map/types';
import PlacesInput from './place-input';

const InputMap: FunctionComponent<Props> = ({
  className,
  onChangeAddress,
  onChangeCoordinates,
  address,
  coordinates,
}) => {
  const { t } = useTranslation('subscription-form');
  const { isLoaded } = useContext(LoadMapContext);
  const mapHandler = useRef<MapHandler>(null);

  const onInputChange = (value: string) => {
    if (!value) {
      onChangeCoordinates(null);
    }

    onChangeAddress(value);
  };

  const onPlaceSelect = (placeTitle: string, coordinates: Coordinates) => {
    mapHandler.current?.setCenter(coordinates);
    onChangeCoordinates(coordinates);
    mapHandler.current?.setZoom(15);
    onChangeAddress(placeTitle);
  };

  if (!isLoaded) return null;

  return (
    <Form.Item className={className} label={t('field.address.label')}>
      <>
        <PlacesInput
          value={address}
          onPlaceSelect={onPlaceSelect}
          onInputChange={onInputChange}
          statusError={!address || !coordinates}
          errorMessage={t('field.address.error') as string}
        />
        <Map className="input-map--map-container" ref={mapHandler}>
          {!!coordinates && <Marker position={coordinates} />}
        </Map>
      </>
    </Form.Item>
  );
};

interface Props extends PropsWithChildren, StyledComponentProps {
  onChangeAddress: (address: string) => void;
  onChangeCoordinates: (coord: Coordinates | null) => void;
  address: string;
  coordinates: Coordinates | null;
}

export default InputMap;

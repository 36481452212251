import styled from 'styled-components';

//Own
import RightSideComponent from './right-side-component';

export default styled(RightSideComponent)`
  position: relative;
  width: 50%;
  background-color: #fef7f9;
  padding: 275px 52px 46px 136px;
  min-width: 600px;

  .login-text__container {
    margin-bottom: 24px;
    max-width: 400px;
    display: flex;

    .title__label {
      font-weight: 700;
      font-size: 16px;
      color: #000000;
    }

    .description__label {
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      color: #787878;
    }

    .check__icon {
      min-width: 16px;
      min-height: 16px;
      margin-top: 2px;
      margin-right: 8px;
      text-align: center;
    }
  }

  @media only screen and (max-width: 576px) {
    width: unset;
    background-color: #fef7f9;
    padding: 52px 52px 136px 52px;
    min-width: 350px;

    .login-text__container {
      margin-bottom: 24px;
      max-width: 100%;
      display: flex;

      .title__label {
        font-weight: 700;
        font-size: 16px;
        color: #000000;
      }

      .description__label {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #787878;
      }

      .check__icon {
        min-width: 16px;
        min-height: 16px;
        margin-top: 2px;
        margin-right: 8px;
        text-align: center;
      }
    }
  }
`;

import { useCallback, useState } from 'react';
import { Form } from 'antd';

//Own
import { Field, FormFields } from '../fields';
import { Image } from '../upload-image/types';
import { Keyword } from '../keywords';
import { Coordinates } from 'core/map/types';
import { Fields } from './types';
import { getDefaultValues } from './get-default-values';

export const useGetFields = (isGeolocated: boolean) => {
  const [form] = Form.useForm<FormFields>();
  const defaultValues = getDefaultValues(isGeolocated);

  const [businessBanner, setBusinessBanner] = useState<Image | null>(null);
  const [businessLogo, setBusinessLogo] = useState<Image | null>(null);
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [addressCoordinates, setAddressCoordinates] =
    useState<Coordinates | null>(defaultValues[Field.AddressCoordinates]);
  const [address, setAddress] = useState(defaultValues[Field.Address]);

  const fields: Fields = {
    [Field.AddressCoordinates]: {
      value: addressCoordinates,
      set: setAddressCoordinates,
    },
    [Field.Address]: {
      value: address,
      set: setAddress,
    },
    [Field.Keywords]: {
      value: keywords,
      set: setKeywords,
    },
    [Field.BusinessBanner]: {
      value: businessBanner,
      set: setBusinessBanner,
    },
    [Field.BusinessLogo]: {
      value: businessLogo,
      set: setBusinessLogo,
    },
  };

  const getFields = useCallback(() => {
    const formValues = form.getFieldsValue();

    return {
      ...formValues,
      businessBanner,
      businessLogo,
      keywords,
      address,
      addressCoordinates,
    };
  }, [
    address,
    addressCoordinates,
    businessBanner,
    businessLogo,
    form,
    keywords,
  ]);

  return { form, getFields, fields };
};

import { API_V3 } from 'constants/env-variables';

export default async function performPreLogin(email: string): Promise<boolean> {
  const url = `${API_V3}/business-markers/user/auth`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ email }),
  }).then(res => res.json() as Promise<Response>);

  if (!response.success) {
    throw response;
  }

  return true;
}

export interface Response {
  success: boolean;
}

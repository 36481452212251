import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

//Own
import { Total } from 'services/metrics/get-metrics/types';
import { useSummaryData } from './use-get-summary-data';

const SummaryComponent: FunctionComponent<{
  className?: string;
  total: Total;
}> = ({ total }) => {
  const { t } = useTranslation('metrics-dashboard');
  const data = useSummaryData(total);

  return (
    <div className="basic__container count--section__panel">
      {data.map(x => {
        return (
          <div>
            <p className="count--section__label">
              {t(`countSection.${x.key}`)}
            </p>
            <p className="count--section__text">{x.value}</p>
          </div>
        );
      })}
    </div>
  );
};

export default SummaryComponent;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Own
import { State, UserLogged } from './types';

const initialState: State = {
  user: null,
};

const authReducer = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserLogged | null>) => {
      state.user = action.payload;
    },
  },
});

export default authReducer;
export const authStateActions = authReducer.actions;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANG, ENV } from 'constants/env-variables';
import { resources } from './resources';

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANG,
  fallbackLng: DEFAULT_LANG,
  debug: ENV === 'dev',
  react: {
    bindI18nStore: 'added',
    useSuspense: false,
  },
});

export default i18n;

import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

//Own
import { ReactComponent as SosafeForBusinessLogo } from 'assets/sosafe-for-business.svg';
import { ReactComponent as PowerIcon } from './power.svg';
import { useAppSelector } from 'state';
import { UserLogged } from 'state/auth/types';
import { useLogout } from 'core/guard-layout/use-logout';

const Header: FunctionComponent<StyledComponentProps> = ({ className }) => {
  const { t } = useTranslation('dashboard');

  const onLogout = useLogout();
  const user = useAppSelector(
    ({ authReducer }) => authReducer.user as UserLogged,
  );

  return (
    <div className={`${className} header__container`}>
      <header className="header__container">
        <SosafeForBusinessLogo className="sfb__icon" />
      </header>
      {user && (
        <div className="log-out__button" onClick={onLogout}>
          <PowerIcon className="power__icon" />
          <span className="log-out__label">{t('logOut')}</span>
        </div>
      )}
    </div>
  );
};

export default styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;

  .header__container {
    min-width: 300px;
    padding-left: 24px;
  }

  .log-out__button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    padding-right: 24px;

    cursor: pointer;

    .log-out__label {
      font-size: 16px;
      font-weight: 600;
      color: #f54286;
    }

    .power__icon {
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
  }

  @media only screen and (max-width: 500px) {
    .header__container {
      min-width: 300px;
    }

    .log-out__button {
      .log-out__label {
        display: none;
      }

      .power__icon {
        width: 20px;
        height: 20px;
        margin-right: 0px;
      }
    }
  }
`;

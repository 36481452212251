import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

//Own
import { Business } from 'services/metrics/get-metrics/types';

const BusinessMarkerComponent: FunctionComponent<{
  className?: string;
  business: Business;
}> = ({ business }) => {
  const { t } = useTranslation('metrics-dashboard');

  return (
    <div className="basic__container business--marker__panel">
      <span className="business-name__label">{business.name}</span>

      <div className="business-due-date__content">
        <p className="business-due-date__label">{t('business.dueDate')}</p>

        <p className="business-due-date__text">
          {business.subscriptionExpiresAt
            ? formatDate(business.subscriptionExpiresAt)
            : t('business.noDueDate')}
        </p>
      </div>
    </div>
  );
};

const formatDate = (date: string): string => {
  return dayjs(date).format('MMMM D, YYYY');
};

export default BusinessMarkerComponent;

import styled from 'styled-components';

//Owm
import FooterComponent from './footer-component';

export default styled(FooterComponent)`
  .contact-feedback__container {
    margin-bottom: 32px;

    .contact-feedback__label-container {
      display: flex;
      padding-bottom: 8px;
    }

    .contact-feedback__label {
      font-size: 16px;
      font-weight: 500;
    }

    .contact-feedback__description {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 16px;
      color: #787878;
    }
  }

  .contact-mediums__container {
    margin-top: 16px;
    margin-bottom: 16px;

    .contact-mediums__label-container {
      display: flex;
      padding-bottom: 8px;
    }

    .contact-mediums__label {
      font-size: 16px;
      font-weight: 500;
    }

    a {
      font-size: 16px;
      font-weight: 400;
      color: #f54286;
    }
  }

  svg {
    min-width: 16px !important;
    margin-right: 6px;
    align-self: center;
    text-align: center;
  }
`;

import { ChangeEventHandler, FunctionComponent } from 'react';
import { Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';

// Own
import { Field, FormFields } from './fields';
import styled from 'styled-components';
import { DEFAULT_COUNTRY_COD } from 'constants/env-variables';

const Phone: FunctionComponent<Props> = ({ className, formInstance }) => {
  const { t } = useTranslation('subscription-form');

  const onCountryCodChange: ChangeEventHandler<HTMLInputElement> = e => {
    const countryCod = formInstance.getFieldValue(Field.CountryCod);

    if (!countryCod)
      formInstance.setFieldValue(Field.CountryCod, DEFAULT_COUNTRY_COD);
  };

  return (
    <Form.Item className={className} label={t('field.phone.label')} required>
      <div className="phone-field--container">
        <Form.Item className="phone-field--cod" name={Field.CountryCod}>
          <Input onChange={onCountryCodChange} />
        </Form.Item>
        <Form.Item
          className="phone-field--number"
          name={Field.Phone}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('field.phone.error') as string,
            },
            { min: 9, message: t('field.phone.error') as string },
          ]}
        >
          <Input placeholder={t('field.phone.placeholder') as string} />
        </Form.Item>
      </div>
    </Form.Item>
  );
};

interface Props extends StyledComponentProps {
  formInstance: FormInstance<FormFields>;
}

export default styled(Phone)`
  margin-bottom: 0;

  .phone-field {
    &--container {
      display: flex;
      gap: 0.5rem;
    }

    &--cod {
      min-width: 55px;
      max-width: 55px;
    }

    &--number {
      flex-grow: 1;
    }
  }
`;

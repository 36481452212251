import { useRef } from 'react';

export const useScrollToBottom = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  return { containerRef, scrollToBottom };
};

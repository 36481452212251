import { Coordinates } from 'core/map/types';
import { Keyword } from './keywords';
import { Image } from './upload-image/types';

export enum Field {
  Name = 'name',
  Email = 'email',
  CountryCod = 'countryCod',
  Phone = 'phone',
  BusinessName = 'businessName',
  RUT = 'RUT',
  Address = 'address',
  AddressCoordinates = 'addressCoordinates',
  ContentURL = 'contentURL',
  CallToAction = 'callToAction',
  Description = 'description',
  Keywords = 'keywords',
  BusinessBanner = 'businessBanner',
  BusinessLogo = 'businessLogo',
  Terms = 'terms',
}

export const FIELDS_REQUIRE_GEOLOCATION = [
  Field.Address,
  Field.AddressCoordinates,
];

export const fieldValidators: Record<Field, (value: any) => boolean> = {
  [Field.Name]: (value: string) => !!value,
  [Field.Email]: (value: string) => !!value,
  [Field.CountryCod]: (value: string) => !!value,
  [Field.Phone]: (value: string) => !!value,
  [Field.BusinessName]: (value: string) => !!value,
  [Field.RUT]: (value: string) => !!value,
  [Field.Address]: (value: string) => !!value,
  [Field.AddressCoordinates]: (value: Coordinates | null) =>
    !!value && !!value.lat && !!value.lng,
  [Field.ContentURL]: (value: string) => !!value,
  [Field.CallToAction]: (value: string) => !!value,
  [Field.Description]: (value: string) => !!value,
  [Field.Keywords]: (value: Keyword[]) => !!value && !!value.length,
  [Field.BusinessBanner]: (file: Image | null) => !!file,
  [Field.BusinessLogo]: (file: Image | null) => !!file,
  [Field.Terms]: (checked: boolean) => checked,
};

export type FormFields = Record<Field, string>;

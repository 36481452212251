import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, ColumnConfig } from '@ant-design/plots';
import { useMediaQuery } from 'react-responsive';

//Own
import { Action, SourceMetricData } from 'services/metrics/get-metrics/types';
import styled from 'styled-components';

const ColumnChart: FunctionComponent<{
  className?: string;
  data: SourceMetricData[];
  action: Action;
}> = ({ className, data, action }) => {
  const { t } = useTranslation('metrics-dashboard');
  const config = useColumnConfig(data);

  return (
    <div className={className}>
      <div className="basic__container">
        <p className="chart__label">{t(`sourceSection.${action}`)}</p>

        <Column {...config} />
      </div>
    </div>
  );
};

const useColumnConfig = (metrics: SourceMetricData[]): ColumnConfig => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { t } = useTranslation('metrics-dashboard');

  return useMemo(() => {
    const data = metrics.map(x => ({
      ...x,
      label: t(`charts.sources.${x.label}`),
    }));

    const config: ColumnConfig = {
      yField: 'count',
      xField: 'label',
      seriesField: 'label',
      isStack: !isMobile,
      color: ['#31CA78', '#F34286', '#2E5AAE', '#C8850D'],
      data,
      label: {
        position: 'middle',
        style: {
          fill: isMobile ? '#000000' : '#FFFFFF',
          opacity: 0.9,
          fontSize: isMobile ? 14 : 16,
        },
      },
      maxColumnWidth: 64,
      meta: {
        count: {
          alias: t(`charts.meta.count`) || '',
        },
      },
    };

    return config;
  }, [metrics, isMobile, t]);
};

export default styled(ColumnChart)`
  .chart__label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px !important;
  }
`;

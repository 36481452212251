import { FunctionComponent } from 'react';

import styled from 'styled-components';
import { Spin } from 'antd';

const LoadingComponent: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  return (
    <div className={className}>
      <div className="loading__container">
        <Spin size="large" />
      </div>
    </div>
  );
};

export default styled(LoadingComponent)`
  background-color: white;
  position: fixed;
  width: 100%;
  height: 100%;

  .loading__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

import styled from 'styled-components';

//Own
import BodyComponent from './body-component';

export default styled(BodyComponent)`
  .business--marker__panel {
    display: flex;
    justify-content: space-between;

    .business-name__label {
      font-weight: 700;
      font-size: 20px;
    }

    .business-due-date__content {
      display: flex;
      align-self: center;

      .business-due-date__label {
        font-weight: 500;
        font-size: 16px;
        margin-right: 4px;
      }

      .business-due-date__text {
        font-weight: 400;
        font-size: 16px;
      }

      .business-due-date__text:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .count--section__panel {
    display: flex;
    justify-content: space-between;

    .count--section__label {
      color: #787878;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 8px;
    }

    .count--section__text {
      font-weight: 500;
      font-size: 24px;
      margin-right: 4px;
    }
  }

  /* On screens that are 600px or less */
  @media screen and (max-width: 600px) {
    .business--marker__panel {
      display: flex;
      flex-direction: column;
    }
  }
`;

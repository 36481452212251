import { useCallback, useEffect, useState } from 'react';

//Own
import { Fields } from './types';

export const useValidateForm = (form: {
  getFieldsValue: Function;
  getFieldError: Function;
}) => {
  const [canSendForm, setCanSendForm] = useState(false);

  const validateRequiredFields = useCallback(() => {
    const fields = form.getFieldsValue();

    const _canSendForm = Object.values(Fields).every(field => {
      const value = fields[field];
      const validate = fieldValidators[field];

      return validate(value);
    });

    setCanSendForm(_canSendForm);
  }, [form]);

  useEffect(() => {
    validateRequiredFields();
  }, [validateRequiredFields]);

  return { canSendForm, validateRequiredFields };
};

export const fieldValidators: Record<Fields, (value: any) => boolean> = {
  [Fields.Email]: (value: string): boolean => {
    const emailValidationRegex =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value?.match(emailValidationRegex)) return true;

    return false;
  },
};

import styled from 'styled-components';
import Terms from './index.component';

export default styled(Terms)`
  width: 100%;
  margin: auto;
  padding: 1rem 2rem;
  max-width: 800px;

  * {
    margin: revert;
    padding: revert;
  }
`;

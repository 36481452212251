import { FunctionComponent } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

// Own
import { Field } from './fields';

const BusinessName: FunctionComponent<Props> = ({ name, className }) => {
  const { t } = useTranslation('subscription-form');

  return (
    <Form.Item
      className={className}
      label={t('field.businessName.label')}
      name={name}
      hasFeedback
      rules={[{ required: true, message: '' }]}
    >
      <Input />
    </Form.Item>
  );
};

interface Props extends StyledComponentProps {
  name: Field;
}

export default BusinessName;

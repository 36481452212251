import { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { Navigate, Outlet } from 'react-router-dom';

//Own
import { useInitializeAuthData } from 'core/guard-layout/use-initialize-auth-data';
import { layoutWidth } from 'styles/layout-width';

const GuardLayout: FunctionComponent<Props> = () => {
  const isAlreadyAuth = useInitializeAuthData();

  if (isAlreadyAuth) {
    return (
      <>
        <Navigate to="/dashboard" />
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Navigate to="/login" />
      <Outlet />
    </>
  );
};

export default styled(GuardLayout)`
  ${layoutWidth}
`;

interface Props extends StyledComponentProps {
  children?: ReactElement;
}

import { Coordinates } from 'core/map/types';
import { toArray, toArrayNumber } from 'utils/to-array';

export const ENV = process.env.REACT_APP_ENV as string;
export const STRICT_MODE_ENABLED = ENV !== 'prod';
export const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG as string;
export const LANGUAGES = toArray(process.env.REACT_APP_LANGUAGES as string);
export const GOOGLE_MAP_API_KEY = process.env
  .REACT_APP_GOOGLE_MAP_API_KEY as string;
export const API_V3 = process.env.REACT_APP_API_V3 as string;
export const MP_KEY_SUBSCRIPTION = process.env
  .REACT_APP_MP_KEY_SUBSCRIPTION as string;

export const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL as string;
export const BUSINESS_BANNER_DIMENSION = toArrayNumber(
  process.env.REACT_APP_BUSINESS_BANNER_DIMENSION,
);
export const BUSINESS_LOGO_DIMENSION = toArrayNumber(
  process.env.REACT_APP_BUSINESS_LOGO_DIMENSION,
);

export const DEFAULT_MAP_ZOOM = 10;

const DEFAULT_MAP_CENTER_ARRAY = toArrayNumber(
  process.env.REACT_APP_DEFAULT_MAP_CENTER as string,
);
export const DEFAULT_MAP_CENTER: Coordinates = {
  lat: DEFAULT_MAP_CENTER_ARRAY[0],
  lng: DEFAULT_MAP_CENTER_ARRAY[1],
};

export const DEFAULT_COUNTRY_COD = process.env
  .REACT_APP_DEFAULT_COUNTRY_COD as string;

export const SHOW_PROMOTION_BANNER =
  process.env.REACT_APP_SHOW_PROMOTION_BANNER === 'true';

export const AMPLITUDE_API_KEY = process.env
  .REACT_APP_AMPLITUDE_API_KEY as string;

export const SOSAFE_WEBSITE_URL = process.env
  .REACT_APP_SOSAFE_WEBSITE_URL as string;

export const SFB_FEEDBACK_FORM_URL = process.env
  .REACT_APP_SFB_FEEDBACK_FORM_URL as string;

export const AUTH_KEY = process.env.REACT_APP_AUTH_LOCAL_STORAGE_KEY as string;

import { FunctionComponent } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

// Own
import { Field } from './fields';

const Email: FunctionComponent<Props> = ({ name, className }) => {
  const { t } = useTranslation('subscription-form');

  return (
    <Form.Item
      className={className}
      label={t('field.email.label')}
      name={name}
      hasFeedback
      rules={[
        { required: true, message: t('field.email.error') as string },
        { type: 'email', message: t('field.email.error') as string },
      ]}
    >
      <Input placeholder={t('field.email.placeholder') as string} />
    </Form.Item>
  );
};

interface Props extends StyledComponentProps {
  name: Field;
}

export default Email;

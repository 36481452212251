import { Button, Form } from 'antd';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// Own
import {
  businessBannerDimension,
  businessLogoDimension,
} from './image-dimensions';
import bannerMockup from './banner-mockup.png';
import logoMockup from './logo-mockup.png';
import UploadImage from './upload-image';
import InputMap from './input-map';
import { Field } from './fields';
import Keywords from './keywords';
import { useSubscriptionId } from './use-subscription-id';
import { useSubmitForm } from './use-submit-form';
import Name from './name';
import Email from './email';
import Phone from './phone';
import BusinessName from './business-name';
import RUT from './rut';
import ContentUrl from './content-url';
import CallToAction from './call-to-action';
import Description from './description';
import { FormData } from './use-submit-form/types';
import { DEFAULT_COUNTRY_COD } from 'constants/env-variables';
import BlockerLoading from 'components/blocker-loading';
import TermsField from './terms-field';
import { useValidateForm } from './use-validation-form';
import { useGetFields } from './use-get-fields';

const SubscriptionForm: FunctionComponent<
  StyledComponentProps & { isGeolocated: boolean }
> = ({ className, isGeolocated }) => {
  const { t } = useTranslation('subscription-form');

  const businessLogoLabel = getBusinessLogoLabel(isGeolocated);
  const subscriptionId = useSubscriptionId();
  const { submit, loading } = useSubmitForm();

  const { form, getFields, fields } = useGetFields(isGeolocated);

  const { activeSubmit, validateRequiredFields } = useValidateForm(
    isGeolocated,
    getFields,
  );

  const scrollToError = () => {
    form.submit();
  };

  const onSubmit = () => {
    const haveErrors = !form
      .getFieldsError()
      .every(field => !field.errors.length);
    if (haveErrors) return scrollToError();

    const formData = getFields();

    submit({ ...formData, subscriptionId } as FormData);
  };

  return (
    <>
      <Form
        className={className}
        layout="vertical"
        form={form}
        onValuesChange={() => validateRequiredFields()}
        initialValues={{
          [Field.CountryCod]: DEFAULT_COUNTRY_COD,
        }}
        autoComplete="off"
        scrollToFirstError
      >
        <Name name={Field.Name} />
        <Email className="half-column" name={Field.Email} />
        <Phone className="half-column" formInstance={form} />
        <BusinessName className="half-column" name={Field.BusinessName} />
        <RUT className="half-column" name={Field.RUT} />

        {isGeolocated && (
          <InputMap
            className="form--label-map"
            address={fields[Field.Address].value}
            coordinates={fields[Field.AddressCoordinates].value}
            onChangeAddress={fields[Field.Address].set}
            onChangeCoordinates={fields[Field.AddressCoordinates].set}
          />
        )}

        <ContentUrl name={Field.ContentURL} />
        <CallToAction name={Field.CallToAction} />
        <Description name={Field.Description} />
        <Keywords
          keywords={fields[Field.Keywords].value}
          onChange={fields[Field.Keywords].set}
        />

        <UploadImage
          required
          dimensions={businessBannerDimension}
          mockupImageSrc={bannerMockup}
          mockupClassName="banner-mockup"
          mockupInfo={t('field.businessBanner.info')}
          label={t('field.businessBanner.label')}
          onRemove={() => fields[Field.BusinessBanner].set(null)}
          onUpload={fields[Field.BusinessBanner].set}
        />

        <UploadImage
          required
          dimensions={businessLogoDimension}
          mockupImageSrc={isGeolocated ? logoMockup : ''}
          mockupClassName="logo-mockup"
          mockupInfo={t('field.businessLogo.info')}
          label={t(`field.businessLogo.${businessLogoLabel}`)}
          onRemove={() => fields[Field.BusinessLogo].set(null)}
          onUpload={fields[Field.BusinessLogo].set}
        />

        <TermsField name={Field.Terms} />

        <Button
          className="form--submit"
          type="primary"
          disabled={!activeSubmit || loading}
          onClick={onSubmit}
        >
          {t('button.submit')}
        </Button>
      </Form>
      {loading && <BlockerLoading />}
    </>
  );
};

const getBusinessLogoLabel = (isGeolocated: boolean): string => {
  return isGeolocated ? 'markerLabel' : 'businessLabel';
};

export default SubscriptionForm;

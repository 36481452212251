import {
  Action,
  ActionData,
  Source,
  SourceMetricData,
} from 'services/metrics/get-metrics/types';

export default function sortSourcesMetrics(
  metrics: SourceMetricData[],
  orderingSequence: Source[],
): SourceMetricData[] {
  return orderingSequence.map(source => {
    return metrics.find(metric => metric.label === source)!;
  });
}

export function getSourcesOrderingSequence(
  sources: ActionData<SourceMetricData[]>,
): Source[] {
  const totalSourcesMetrics = getTotalSourcesMetrics(sources);
  return getOrderingSequenceBySourcesMetrics(totalSourcesMetrics);
}

function getOrderingSequenceBySourcesMetrics(
  metrics: SourceMetricData[],
): Source[] {
  return metrics.sort((a, b) => b.count - a.count).map(metric => metric.label);
}

function getTotalSourcesMetrics(
  sources: ActionData<SourceMetricData[]>,
): SourceMetricData[] {
  const totalSourcesMetrics: SourceMetricData[] = [];

  Object.keys(sources).forEach(key => {
    const source = sources[key as Action];

    source.forEach(sourceMetric => {
      const { label, count } = sourceMetric;
      const index = totalSourcesMetrics.findIndex(
        totalSourceMetric => totalSourceMetric.label === label,
      );

      if (index <= -1) {
        totalSourcesMetrics.push({ label, count });
      } else {
        totalSourcesMetrics[index].count += count;
      }
    });
  });

  return totalSourcesMetrics;
}

import styled from 'styled-components';
import Keywords from './index.component';

// Own
import { inputTextLarge } from 'styles/input-text';

export * from './types';

export default styled(Keywords)`
  height: 150px;
  padding: 0.25rem 0.5rem;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 4px;

  &:focus-within {
    border-color: #ff6ea1;
  }
  &:hover {
    cursor: text;
  }

  .keywords--inner-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;
  }

  .keywords--tag {
    padding: 4px 11px;
    font-size: 16px;
  }

  .keywords--input {
    flex-grow: 1;
    width: 25%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media (max-width: 800px) {
    .keywords--input,
    .keywords--tag {
      ${inputTextLarge}
    }
  }
`;

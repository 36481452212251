import { AUTH_KEY } from 'constants/env-variables';
import { useNavigate } from 'react-router-dom';

//Own
import { useAppDispatch } from 'state';
import { authStateActions } from 'state/auth';
import { UserLogged } from 'state/auth/types';

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (data: UserLogged) => {
    dispatch(authStateActions.setUser(data));
    localStorage.setItem(AUTH_KEY, JSON.stringify(data));
    navigate('/dashboard', { replace: true });
  };
};

import { API_V3 } from 'constants/env-variables';
import {
  PresignedUrl,
  RawPresignedUrl,
  Response,
  ErrorResponse,
} from './types';

export * from './types';

export default function getPresignedUrls(imageMimeTypes: string[]) {
  const queryParams = imageMimeTypes.map(x => `files=${x}`).join('&');

  const url = `${API_V3}/business-markers/uploads/presigned-url?${queryParams}`;

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  })
    .then(response => response.json() as Promise<Response>)
    .then(data => {
      if (!data.success) return { success: data.success };

      const { success, urls } = data;

      return { success, urls: setAssetsOriginUrl(urls) };
    })
    .catch(() => ({ success: false } as ErrorResponse));
}

function setAssetsOriginUrl(presignedUrls: RawPresignedUrl[]): PresignedUrl[] {
  return presignedUrls.map(x => {
    const presignedUrl = new URL(x.url);

    const assetUrl = `${presignedUrl.origin}${presignedUrl.pathname}`;

    return {
      ...x,
      assetUrl,
    };
  });
}

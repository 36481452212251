import { FunctionComponent, PropsWithChildren } from 'react';
import { ConfigProvider } from 'antd';
import antdEs from 'antd/locale/es_ES';

const AntdConfigProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F54286',
          colorWarning: '#FF3533',
          colorError: '#FF3533',
          colorText: '#3A4151',
          colorTextSecondary: '#6E788F',
          colorLink: '#F54286',
        },
      }}
      locale={antdEs}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntdConfigProvider;

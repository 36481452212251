export interface Plan {
  title: Text;
  features: Features;
  paymentLink: string;
  period: string;
  price: string;
  coupon: string;
  discountRate: string;
  priceWithDiscount: string;
  saving: string;
  isRecommended: boolean;
}

export interface Text {
  en: string;
  es: string;
  fr: string;
}

export type Features = Record<string, string[]>;

export enum ErrorType {
  InvalidCode = 'invalid-code',
  Unknown = 'unknown',
}

import styled from 'styled-components';

//Owm
import ErrorComponent from './error-component';

export default styled(ErrorComponent)`
  background-color: white;
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;

  .error__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .title__component {
      font-weight: 600;
      font-size: 22px;
      max-width: 400px;
      margin-bottom: 14px;
    }

    .description__component {
      color: #787878;
      font-weight: 400;
      font-size: 15px;
      max-width: 400px;
    }

    .email__label {
      color: #f54286;
      font-weight: 400;
      font-size: 15px;
      text-decoration: none;
    }

    svg {
      width: 100%;
      margin-bottom: 14px;
    }
  }
`;

import { AUTH_KEY } from 'constants/env-variables';
import { useNavigate } from 'react-router-dom';

//Own
import { useAppDispatch } from 'state';
import { authStateActions } from 'state/auth';

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return () => {
    dispatch(authStateActions.setUser(null));
    localStorage.removeItem(AUTH_KEY);
    navigate('/login', { replace: true });
  };
};

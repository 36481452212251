import { FunctionComponent } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';

// Own
import { layoutWidth } from 'styles/layout-width';
import { CONTACT_EMAIL } from 'constants/env-variables';
import { CheckCircleFilled } from '@ant-design/icons';

const SubscriptionSuccess: FunctionComponent<StyledComponentProps> = ({
  className,
}) => {
  const { t } = useTranslation('subscription-success');

  return (
    <div className={className}>
      <h1 className="success-title">
        <CheckCircleFilled className="succes-icon" />
        <span>{t('title')}</span>
      </h1>
      <Trans
        t={t}
        i18nKey={'description'}
        values={{ contactEmail: CONTACT_EMAIL }}
        components={[
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a className="contact-email" href={`mailto:${CONTACT_EMAIL}`} />,
        ]}
      />
    </div>
  );
};

export default styled(SubscriptionSuccess)`
  ${layoutWidth}

  .contact-email {
    color: #f54286;
    text-decoration: none;
  }

  .success-title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    margin-bottom: 1.5rem;
  }

  .succes-icon {
    color: #31ca78;
    font-size: 1.5rem;
  }
`;

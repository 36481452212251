import styled, { css } from 'styled-components';
import SubscriptionForm from './index.component';

import { inputTextLarge } from 'styles/input-text';

const customRequiredSymbol = css`
  display: inline;
  margin-left: 0.25rem;
  color: #f54286;
  font-size: 0.9rem;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
`;

const gridColumnsAsFilledRow = '1/-1';

export default styled(SubscriptionForm)`
  display: grid;
  align-items: baseline;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;

  & > * {
    grid-column: ${gridColumnsAsFilledRow};
  }

  .half-column {
    grid-column: auto / span 1;
  }

  .form--submit {
    justify-self: end;
  }

  .form--label-map label {
    font-weight: 600;
    font-size: 1rem;

    &::after {
      ${customRequiredSymbol}
    }
  }

  .banner-mockup img {
    width: 320px;
  }

  .logo-mockup img {
    width: 200px;
  }

  // Override Ant styles
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    &::before {
      display: none;
    }

    &::after {
      ${customRequiredSymbol}
    }
  }

  .ant-form-item-required,
  .ant-form-item-required-mark-optional {
    font-size: 1rem !important;
    font-weight: 600;
    display: inline-block !important;

    .anticon-info-circle {
      vertical-align: middle !important;
    }

    .ant-form-item-optional {
      font-weight: 500;
    }
  }

  @media (max-width: 800px) {
    .half-column {
      grid-column: ${gridColumnsAsFilledRow};
    }

    .banner-mockup img {
      width: 270px;
    }

    .logo-mockup img {
      width: 170px;
    }

    .ant-input,
    .ant-input-affix-wrapper {
      ${inputTextLarge}
    }
  }
`;

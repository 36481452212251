export default {
  field: {
    name: {
      label: 'Nombre y Apellido del solicitante',
    },
    email: {
      label: 'Correo electrónico de contacto',
      placeholder: 'tunegocio@gmail.com',
      error:
        'Ingresa un formato válido de correo electrónico que incluya “@”. Ejemplo: sosafe@app.com.',
    },
    phone: {
      label: 'Teléfono de contacto',
      placeholder: '9 xxxx-xxxx / 2 xxx-xxx-xxx',
      error:
        'Ingresa un formato válido de teléfono. Prefijo 2 para teléfonos fijos y 9 para teléfonos móviles. Ejemplo: 912345678.',
    },
    businessName: {
      label: 'Nombre de tu negocio, tienda o marca',
    },
    RUT: {
      label: 'RUT del negocio',
      placeholder: '12345678K',
      info: 'Será utilizado para tu facturación',
      error: 'Ingresa un RUT válido, sin puntos ni guión.',
    },
    address: {
      label: 'Dirección de tu negocio',
      error:
        'Ingresa un formato válido de dirección indicando calle y número. Selecciona de las opciones sugeridas.',
    },
    contentURL: {
      label:
        'Enlace al que quieres que se dirijan los usuarios (página web, red social, etc.)',
      placeholder: 'https://www.sosafeapp.com/',
      error:
        'Ingresa el enlace bajo el formato “www.” Ejemplo: www.sosafeapp.com.',
    },
    callToAction: {
      label: 'Ingresa el texto de acción del enlace (máximo 15 caracteres)',
      placeholder: 'Ej: Ir a comprar',
      info: 'Este texto aparecerá en el botón principal del anuncio. El usuario al apretarlo, lo dirigirá a tu enlace.<br/><br/>Texto recomendado: Ir a comprar, visitar tienda',
    },
    description: {
      label: 'Breve descripción de tu negocio (máximo 90 caracteres)',
      placeholder: 'Ej: El mejor café del barrio',
    },
    keywords: {
      label:
        'Ingresa palabras claves relacionadas a tu negocio separadas por coma (,) para que lo encuentren en el buscador del Mercado SOSAFE. Por ejemplo: Calzado, Café, Bolso de mano, etc.',
      placeholder:
        'Ej: Alicates, Destornilladores, Llaves ajustables, Cepillos metálicos, Extractores, Escaleras',
      info: 'Por ejemplo, si tienes un restaurant, puedes colocar el menú que ofreces. Si tienes una ferretería, puedes escribir todas las herramientas que vendes.',
    },
    businessBanner: {
      label: 'Banner de tu negocio para los comentarios y noticias',
      info: 'Ejemplo de cómo se vería tu banner',
    },
    businessLogo: {
      markerLabel:
        'Logo o imagen de tu negocio para el mapa del Mercado y buscador',
      businessLabel: 'Logo o imagen de tu negocio',
      info: 'Ejemplo de cómo se vería tu logo',
    },
    terms: {
      label: 'Acepto todos los <0>términos y condiciones</0>.',
    },
  },
  button: {
    uploadFile: 'Subir archivo',
    submit: 'Finalizar',
  },
  fileDescription: {
    dimensions: 'Dimensiones:',
    format: 'Formato:',
    conector: 'y',
  },
  onSubmitError:
    'Ha ocurrido un error al registrar tu suscripción. Por favor, reintenta.',
};

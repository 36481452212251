import styled from 'styled-components';

//Owm
import MetricsDashboard from './metrics-dashboard';

export default styled(MetricsDashboard)`
  background-color: #f8f8f8;
  padding-bottom: 24px;

  .header__container {
    padding: 24px;
  }

  svg {
    min-width: 250px;
    align-self: flex-start;
  }

  .basic__container {
    background-color: white;
    margin: 0px 24px 24px 24px;
    padding: 16px;
    border-radius: 8px;
  }
`;

import styled from 'styled-components';
import SubscriptionHeader from './index.component';

// Own
import { layoutWidth } from 'styles/layout-width';

export default styled(SubscriptionHeader)`
  ${layoutWidth}

  margin-top: 2.5rem;
  margin-bottom: 1.5rem;

  display: flex;

  svg {
    min-width: 250px;
    align-self: flex-start;
  }
`;

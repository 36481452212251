export interface Payload {
  startDate: string;
  endDate: string;
  businessUuid: string;
  timezoneGMT: string | null;
}

export interface Data {
  metrics: SosafeAdsMetrics;
  business: Business;
  total: Total;
}

export type Total = {
  ctr: number;
} & Record<Action, number>;

export enum Action {
  Click = 'click',
  Impression = 'impression',
}

export enum Source {
  Comments = 'comments',
  ReportFeed = 'report-feed',
  Map = 'map',
  Search = 'search',
}

export type SosafeAdsMetrics = {
  actions: ActionMetrics;
  sources: ActionSourceMetrics;
};

export type ActionData<T> = {
  [action in Action]: T;
};

export type ActionMetrics = ActionData<{
  count: number;
  data: ActionMetricData[];
}>;

export type ActionSourceMetrics = ActionData<SourceMetricData[]>;

export interface ActionMetricData {
  date: string;
  count: number;
}

export interface SourceMetricData {
  label: Source;
  count: number;
}

export interface Metric {
  date: string;
  count: number;
}

export interface Business {
  name: string;
  subscriptionExpiresAt: string;
}

import styled from 'styled-components';
import InputMap from './index.component';

export default styled(InputMap)`
  .input-map {
    &--map-container {
      width: 100%;
      height: 350px;
    }

    &--places-container {
      margin-bottom: 1.5rem;
    }
  }
`;

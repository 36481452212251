import { useNavigate } from 'react-router-dom';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Avatar } from 'antd';
import dayjs from 'dayjs';

//Own
import { Business } from '../use-get-businesses';
import { ReactComponent as RigthArrowIcon } from 'assets/rigth-arrow.svg';

const BusinessCard: FunctionComponent<Props> = ({ data }) => {
  const { uuid, name, avatarImageUrl, updatedAt, expiredAt } = data;
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();

  const handleAction = () => {
    navigate(`/dashboard/metrics/${uuid}`, { replace: false });
  };

  return (
    <div className="card__container">
      <div className="card-item__container" onClick={handleAction}>
        <div className="card-image__container">
          <Avatar
            alt="business logo"
            className="card__image"
            src={avatarImageUrl}
          />
        </div>
        <Divider orientation="right" className="card__divider" />
        <div className="card-text__container">
          {name} <RigthArrowIcon />
        </div>
      </div>

      <div className="date__container">
        <span className="date-label__bold">{t('businessCard.lastPost')}</span>
        <span className="date__container-text">{`${formatDate(
          updatedAt,
        )}`}</span>
      </div>

      <div className="date__container">
        {expiredAt ? (
          <>
            <span className="date-label__bold">
              {t('businessCard.dueDate')}
            </span>
            <span className="date__container-text">{`${formatDate(
              expiredAt,
            )}`}</span>
          </>
        ) : (
          <span>{t('businessCard.noDueDate')} </span>
        )}
      </div>
    </div>
  );
};

const formatDate = (date: string): string => {
  const rawData = dayjs(date);
  const rawDataFormatted = rawData.format('D {t}, YYYY');
  const rawMonth = rawData.format('MMM');

  return `${rawDataFormatted.replace(
    '{t}',
    rawMonth[0].toUpperCase() + rawMonth.slice(1),
  )} `;
};

export default BusinessCard;

interface Props extends StyledComponentProps {
  data: Business;
}

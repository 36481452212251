export default {
  leftSide: {
    title: 'Acceso al portal por correo electrónico',
    description: 'Recibirás un enlace que te permitirá acceder al portal.',
    email: {
      label: 'Correo electrónico',
      placeHolder: 'Ingresa el correo electrónico asociado a tu cuenta',
      error:
        'Ingresa un formato válido de correo electrónico que incluya “@”. Ejemplo: sosafe@app.com.',
    },
    sendFormBtnLabel: 'Enviar correo electrónico',
  },
  rightSide: {
    boost: {
      title: 'Impulsa tu negocio con SOSAFE',
      description: 'Promociona tu negocio local y online',
    },
    manage: {
      title: 'Administra tu negocio y publicidad',
      description: 'Controla, monitorea y edita tus anuncios',
    },
    analytics: {
      title: 'Analíticas y métricas',
      description:
        'Monitorea y optimiza el rendimiento de tus anuncios publicitarios.',
    },
  },
  errorMessage:
    'Ocurrió un error al intentar enviar el correo electrónico. Por favor, reintenta.',
  successMessage:
    'Correo electrónico enviado. Si tienes una cuenta asociada, recibirás un enlace para acceder al portal.',
};

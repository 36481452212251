import { FunctionComponent } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

// Own
import { Field } from './fields';

const Description: FunctionComponent<Props> = ({ name }) => {
  const { t } = useTranslation('subscription-form');

  return (
    <Form.Item
      label={t('field.description.label')}
      name={name}
      hasFeedback
      rules={[
        { required: true, message: '' },
        { max: 90, message: '' },
      ]}
    >
      <Input placeholder={t('field.description.placeholder') as string} />
    </Form.Item>
  );
};

interface Props {
  name: Field;
}

export default Description;

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import { Alert } from 'antd';

//Own
import Header from 'components/header';
import { usePerformPreLogin } from '../use-perform-pre-login';
import { useValidateForm } from './use-validate-form';
import { FormFields } from './types';

const LeftSideComponent: FunctionComponent<StyledComponentProps> = ({
  className,
}) => {
  const { t } = useTranslation('login');
  const [form] = Form.useForm<FormFields>();

  const { performPreLogin, loading, message, resetMessage } =
    usePerformPreLogin();
  const { canSendForm, validateRequiredFields } = useValidateForm(form);

  const scrollToError = () => {
    form.submit();
  };

  const onSubmit = async () => {
    const haveErrors = !form
      .getFieldsError()
      .every(field => !field.errors.length);

    if (haveErrors) return scrollToError();
    const formValues = form.getFieldsValue();

    await performPreLogin(formValues.email);
  };

  return (
    <div className={className}>
      <Header />
      <p className="title__label">{t('leftSide.title')}</p>
      <p className="description__label">{t('leftSide.description')}</p>

      <Form
        className={'form__container'}
        layout="vertical"
        form={form}
        onValuesChange={() => {
          validateRequiredFields();
          message && resetMessage();
        }}
        autoComplete="off"
        scrollToFirstError
      >
        <Form.Item
          className={'email__input'}
          label={
            <p className="email-input__label">{t('leftSide.email.label')}</p>
          }
          name={'email'}
          hasFeedback
          rules={[
            { required: true, message: t('leftSide.email.error') as string },
            { type: 'email', message: t('leftSide.email.error') as string },
          ]}
        >
          <Input
            placeholder={t('leftSide.email.placeHolder') as string}
            required
          />
        </Form.Item>

        <Button
          className="submit__btn"
          type="primary"
          disabled={!canSendForm || loading}
          loading={loading}
          onClick={onSubmit}
        >
          {t('leftSide.sendFormBtnLabel')}
        </Button>
      </Form>

      <div className="alert__container">
        {message && (
          <Alert description={message.text} type={message.type} showIcon />
        )}
      </div>
    </div>
  );
};

export default LeftSideComponent;

import { API_V3 } from 'constants/env-variables';
import { BusinessForm, Response } from './types';

export default function saveBusinessPreRegisterData(
  payload: BusinessForm,
): Promise<Response> {
  return fetch(`${API_V3}/business-markers/pre-register`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(res => res.json())
    .catch(() => ({ success: false }));
}

import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

//Own
import { ReactComponent as CheckIcon } from 'assets/check-circle.svg';

const RightSideComponent: FunctionComponent<StyledComponentProps> = ({
  className,
}) => {
  const { t } = useTranslation('login');

  const businessBenefitsText = useMemo(
    () => [
      {
        title: t('rightSide.boost.title'),
        description: t('rightSide.boost.description'),
      },
      {
        title: t('rightSide.manage.title'),
        description: t('rightSide.manage.description'),
      },
      {
        title: t('rightSide.analytics.title'),
        description: t('rightSide.analytics.description'),
      },
    ],
    [t],
  );

  return (
    <div className={className}>
      {businessBenefitsText.map(detail => (
        <div key={detail.title} className="login-text__container">
          <CheckIcon className="check__icon" />
          <div>
            <p className="title__label">{detail.title}</p>
            <p className="description__label">{detail.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RightSideComponent;

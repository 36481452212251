import { FunctionComponent } from 'react';
import { Form, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

// Own
import { Field } from './fields';

const RUT: FunctionComponent<Props> = ({ name, className }) => {
  const { t } = useTranslation('subscription-form');

  return (
    <Form.Item
      className={className}
      label={t('field.RUT.label')}
      name={name}
      hasFeedback
      rules={[
        { required: true, message: t('field.RUT.error') as string },
        { min: 8, max: 10, message: t('field.RUT.error') as string },
      ]}
      tooltip={{
        title: t('field.RUT.info'),
        icon: <InfoCircleOutlined />,
      }}
    >
      <Input placeholder={t('field.RUT.placeholder') as string} />
    </Form.Item>
  );
};

interface Props extends StyledComponentProps {
  name: Field;
}

export default RUT;

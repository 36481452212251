import { DefaultFields } from './types';

export const getDefaultValues = (isGeolocated: boolean): DefaultFields => {
  return {
    address: isGeolocated ? '' : 'N/A',
    addressCoordinates: isGeolocated
      ? null
      : {
          lat: 0,
          lng: 0,
        },
  };
};

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

//Own
import performPreLoginRequest from 'services/auth/perform-pre-login';

export const usePerformPreLogin = (): Response => {
  const { t } = useTranslation('login');

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const resetMessage = () => setMessage(null);

  const performPreLogin = useCallback(
    async (email: string) => {
      setLoading(true);

      try {
        if (email) {
          await performPreLoginRequest(email);
          setMessage({ type: 'success', text: t('successMessage') });
          return;
        }

        throw new Error('Invalid payload');
      } catch (error) {
        setMessage({ type: 'error', text: t('errorMessage') });
      } finally {
        setLoading(false);
      }
    },
    [t],
  );

  return { performPreLogin, loading, message, resetMessage };
};

interface Response {
  performPreLogin(email: string): Promise<void>;
  loading: boolean;
  message: Message | null;
  resetMessage(): void;
}

interface Message {
  type: 'error' | 'success';
  text: string;
}

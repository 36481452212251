import { combineReducers } from 'redux';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

// Own
import { ENV } from 'constants/env-variables';
import authReducer from './auth';

const appReducer = combineReducers({
  authReducer: authReducer.reducer,
});

export const store = configureStore({
  devTools: ENV !== 'prod',
  reducer: appReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

// TypeScript helpers.
// See https://redux-toolkit.js.org/usage/usage-with-typescript
export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
// Strongly typed useDispatch and useSelector hooks with our store type definitions.
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppMiddlewareGetState = () => RootState;
export type AppReduxMiddleware = (
  dispatch: AppDispatch,
  getState: () => RootState,
) => void | Promise<void>;

import styled from 'styled-components';
import Subscription from './index.component';

// Own
import { layoutWidth } from 'styles/layout-width';

export default styled(Subscription)`
  ${layoutWidth}

  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;

  .subscription-title {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .subscription-description {
    margin-bottom: 4rem;
  }

  .subscription-email {
    color: #f54286;
    text-decoration: none;
  }

  @media (min-width: 800px) {
    .subscription-form {
      align-self: center;
      min-width: 500px;
      width: 50%;
      max-width: 757px;
    }
  }
`;

import { FunctionComponent } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

// Own
import { Field } from './fields';

const Name: FunctionComponent<Props> = ({ name }) => {
  const { t } = useTranslation('subscription-form');

  return (
    <Form.Item
      label={t('field.name.label')}
      name={name}
      hasFeedback
      rules={[{ required: true, message: '' }]}
    >
      <Input />
    </Form.Item>
  );
};

interface Props {
  name: Field;
}

export default Name;

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'app';
import { STRICT_MODE_ENABLED } from 'constants/env-variables';
import 'utils/i18n';
import 'utils/metrics';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  STRICT_MODE_ENABLED ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  ),
);

//Own
import { Action, Total } from 'services/metrics/get-metrics/types';

export const useSummaryData = (total: Total): NewTotal[] => {
  return Object.values(KeysOrder).map((x): NewTotal => {
    const key = x as KeyOfTotal;

    return {
      key,
      value: total[key],
    };
  });
};

const KeysOrder: KeyOfTotal[] = ['ctr', Action.Click, Action.Impression];

type KeyOfTotal = keyof Total;
type NewTotal = {
  key: KeyOfTotal;
  value: number;
};

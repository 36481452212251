import { useEffect, useState } from 'react';

export const useMarkdown = (rawMarkdown: string) => {
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    fetch(rawMarkdown)
      .then(res => res.text())
      .then(setMarkdownContent);
  }, [rawMarkdown]);

  return markdownContent;
};

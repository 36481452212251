import styled from 'styled-components';
import LoginComponent from './login-component';

export default styled(LoginComponent)`
  display: flex;
  position: relative;
  min-width: 1200px;

  .balloons-icon__component {
    position: absolute;
    right: 0px;
    height: 80vh;
    margin-top: 160px;
    margin-right: 52px;
  }

  @media only screen and (max-width: 576px) {
    display: unset;
    position: relative;
    min-width: unset;

    .balloons-icon__component {
      display: none;
    }
  }
`;

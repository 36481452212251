import { FunctionComponent, useRef } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Input, Dropdown } from 'antd';

// Own
import { Coordinates } from 'core/map/types';

const PlacesInput: FunctionComponent<Props> = ({
  className,
  value,
  statusError,
  errorMessage,
  onPlaceSelect,
  onInputChange,
}) => {
  const inputTouchedRef = useRef(false);

  const onChangeInput = (value: string) => {
    inputTouchedRef.current = true;

    onInputChange(value);
  };

  const onSelectAddress = (value: string) => {
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        onPlaceSelect(value, latLng);
      })
      .catch(() => {});
  };

  const inputStatus = inputTouchedRef.current && statusError ? 'error' : '';

  return (
    <div className={`${className} places-input-container`}>
      <PlacesAutocomplete
        value={value}
        onChange={onChangeInput}
        onSelect={onSelectAddress}
        highlightFirstSuggestion
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Dropdown
            mouseLeaveDelay={1}
            overlayClassName={`${className} autocomplete-place--dropdown-suggestion`}
            open={!!suggestions.length && !loading}
            menu={{
              items: suggestions.map(suggestion => {
                const itemClassName = suggestion.active
                  ? 'suggestion-item-active'
                  : '';

                const suggestionItemProps = getSuggestionItemProps(suggestion, {
                  className: itemClassName,
                });

                return {
                  key: suggestionItemProps.key,
                  label: (
                    <div {...suggestionItemProps}>{suggestion.description}</div>
                  ),
                };
              }),
            }}
          >
            <Input
              {...getInputProps({
                className: `${className} autocomplete-place--input`,
              })}
              status={inputStatus}
              autoComplete="none"
              required
            />
          </Dropdown>
        )}
      </PlacesAutocomplete>
      {!!inputStatus && !!errorMessage && (
        <div className="places-input--error-message">{errorMessage}</div>
      )}
    </div>
  );
};

interface Props extends StyledComponentProps {
  value: string;
  onPlaceSelect: (placeTitle: string, coordinates: Coordinates) => void;
  onInputChange: (value: string) => void;
  statusError: boolean;
  errorMessage?: string;
}

export default PlacesInput;

import { GoogleMap } from '@react-google-maps/api';
import {
  PropsWithChildren,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
  useContext,
} from 'react';
import { LoadMapContext } from './load-map-context';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from 'constants/env-variables';
import { MapHandler } from './types';

const Map: ForwardRefRenderFunction<MapHandler, Props> = (
  { children, className },
  ref,
) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const { isLoaded } = useContext(LoadMapContext);

  useImperativeHandle(ref, () => ({
    setCenter: center => mapRef.current?.setCenter(center),
    setZoom: zoom => mapRef.current?.setZoom(zoom),
  }));

  if (!isLoaded) return null;

  return (
    <GoogleMap
      onLoad={map => {
        mapRef.current = map;
      }}
      mapContainerClassName={className}
      center={DEFAULT_MAP_CENTER}
      zoom={DEFAULT_MAP_ZOOM}
      clickableIcons={false}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
    >
      {children}
    </GoogleMap>
  );
};

type Props = PropsWithChildren & StyledComponentProps;

export default forwardRef(Map);

import { FunctionComponent } from 'react';

//Own
import { Action, Data } from 'services/metrics/get-metrics/types';
import BusinessMarkerComponent from './business-marker-component';
import SummaryComponent from './summary-component';
import LineChart from './line-chart';
import ColumnChart from './column-chart';
import sortSourcesMetrics, {
  getSourcesOrderingSequence,
} from './sort-sources-metrics';

const BodyComponent: FunctionComponent<{ className?: string; data: Data }> = ({
  className,
  data,
}) => {
  const { business, total, metrics } = data;
  const orderingSequence = getSourcesOrderingSequence(metrics.sources);

  return (
    <div className={className}>
      <BusinessMarkerComponent business={business} />
      <SummaryComponent total={total} />
      <LineChart
        key={Action.Click}
        action={Action.Click}
        data={metrics.actions[Action.Click].data}
      />
      <ColumnChart
        key={Action.Click}
        action={Action.Click}
        data={sortSourcesMetrics(
          metrics.sources[Action.Click],
          orderingSequence,
        )}
      />
      <LineChart
        key={Action.Impression}
        action={Action.Impression}
        data={metrics.actions[Action.Impression].data}
      />
      <ColumnChart
        key={Action.Impression}
        action={Action.Impression}
        data={sortSourcesMetrics(
          metrics.sources[Action.Impression],
          orderingSequence,
        )}
      />
    </div>
  );
};

export default BodyComponent;

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

//Own
import {
  CONTACT_EMAIL,
  SOSAFE_WEBSITE_URL,
  SFB_FEEDBACK_FORM_URL,
} from 'constants/env-variables';
import { ReactComponent as FeedbackIcon } from './feedback-icon.svg';
import { ReactComponent as EmailIcon } from './email-icon.svg';
import { ReactComponent as SosafeIcon } from './sosafe-icon.svg';

const FooterComponent: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  const { t } = useTranslation('metrics-dashboard');

  return (
    <div className="basic__container">
      <div className={className}>
        <div className="contact-feedback__container">
          <div className="contact-feedback__label-container">
            <FeedbackIcon />
            <p className="contact-feedback__label">
              {t('footer.feedBackLabel')}
            </p>
          </div>

          <p className="contact-feedback__description">
            {t('footer.feedBackText')}
          </p>

          <Button
            href={SFB_FEEDBACK_FORM_URL}
            type="primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.formButton')}
          </Button>
        </div>

        <div className="contact-mediums__container">
          <div className="contact-mediums__label-container">
            <EmailIcon />
            <p className="contact-mediums__label">{t('footer.email')}</p>
          </div>

          <a href={`mailto:${CONTACT_EMAIL}`} rel="noreferrer">
            {CONTACT_EMAIL}
          </a>
        </div>

        <div className="contact-mediums__container">
          <div className="contact-mediums__label-container">
            <SosafeIcon />
            <span className="contact-mediums__label">
              {t('footer.website')}
            </span>
          </div>
          <a target="_blank" href={`${SOSAFE_WEBSITE_URL}`} rel="noreferrer">
            {SOSAFE_WEBSITE_URL}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;

import { Form, Checkbox } from 'antd';
import { FunctionComponent } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Field } from './fields';

const TermsField: FunctionComponent<Props> = ({ name }) => {
  const { t } = useTranslation('subscription-form');

  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      hasFeedback
      rules={[{ required: true, message: '' }]}
    >
      <Checkbox>
        <Trans
          t={t}
          i18nKey={'field.terms.label'}
          components={[<Link to="/terms" target="_blank" />]}
        />
      </Checkbox>
    </Form.Item>
  );
};

export default TermsField;

interface Props {
  name: Field;
}

import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Line, LineConfig } from '@ant-design/plots';
import dayjs from 'dayjs';

//Own
import { Action, Metric } from 'services/metrics/get-metrics/types';
import styled from 'styled-components';

const LineChart: FunctionComponent<{
  className?: string;
  data: Metric[];
  action: Action;
}> = ({ className, data, action }) => {
  const { t } = useTranslation('metrics-dashboard');
  const config = useLineConfig(data);

  return (
    <div className={className}>
      <div className="basic__container">
        <p className="chart__label">{t(`charts.actions.${action}`)}</p>

        <Line {...config} />
      </div>
    </div>
  );
};

const useLineConfig = (metrics: Metric[]): LineConfig => {
  const { t } = useTranslation('metrics-dashboard');

  return useMemo(() => {
    const data = metrics.map(x => ({
      ...x,
      date: applyDateFormat(x.date),
    }));

    return {
      data,
      xField: 'date',
      yField: 'count',
      point: {
        size: 5,
        shape: 'diamond',
      },
      color: '#F54286',
      meta: {
        count: {
          alias: t(`charts.meta.count`) || '',
        },
      },
    };
  }, [metrics, t]);
};

const applyDateFormat = (date: string): string => {
  return dayjs(date).format('DD-MM-YYYY');
};

export default styled(LineChart)`
  .chart__label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px !important;
  }
`;

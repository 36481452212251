import { useLayoutEffect } from 'react';

export default function useGlobalStyles() {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = '#FFF0F7';

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);
}

export const getImageDimension = (file: File): Promise<Dimension | null> => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.src = URL.createObjectURL(file);

    image.onload = () => {
      const width = image.width;
      const height = image.height;
      resolve({ width, height });
    };

    image.onerror = () => {
      reject(null);
    };
  });
};

export interface Dimension {
  width: number;
  height: number;
}

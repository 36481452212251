import { FunctionComponent } from 'react';
import { Form, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';

// Own
import { Field } from './fields';

const CallToAction: FunctionComponent<Props> = ({ name }) => {
  const { t } = useTranslation('subscription-form');

  return (
    <Form.Item
      label={t('field.callToAction.label')}
      name={name}
      hasFeedback
      tooltip={{
        title: <Trans t={t} i18nKey={'field.callToAction.info'} />,
        icon: <InfoCircleOutlined />,
      }}
      rules={[
        { required: true, message: '' },
        { max: 15, message: '' },
      ]}
    >
      <Input placeholder={t('field.callToAction.placeholder') as string} />
    </Form.Item>
  );
};

interface Props {
  name: Field;
}

export default CallToAction;

//Own
import { API_V3 } from 'constants/env-variables';
import { Business } from 'core/dashboard/use-get-businesses';

async function getUserBusinesses(accessToken: string): Promise<Business[]> {
  const url = `${API_V3}/business-markers/user/business`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'X-SOSAFE-ACCESSTOKEN': accessToken,
    },
    method: 'GET',
  }).then(response => response.json() as Promise<RawResponse>);

  if (!response.success || !response.data) {
    throw response;
  }

  return response.data;
}

export default getUserBusinesses;

export interface RawResponse {
  success: boolean;
  data?: Business[];
}

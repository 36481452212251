import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Own
import saveBusinessPreRegisterData from 'services/subscription/pre-register';
import { Image as UploadedImage } from 'services/subscription/pre-register/types';
import { BusinessImageType } from '../types';
import { useUploadImages, ImageWithType } from '../use-upload-image';
import { FormData } from './types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

export const useSubmitForm = () => {
  const { t } = useTranslation('subscription-form');

  const [loading, setLoading] = useState(false);

  const uploadImages = useUploadImages();

  const navigate = useNavigate();

  const onError = useCallback(() => {
    setLoading(false);
    message.error(t('onSubmitError'));
  }, [t]);

  const goToSuccessView = useCallback(() => {
    navigate('/subscription/success', { replace: true });
  }, [navigate]);

  const submit = useCallback(
    async (formData: FormData) => {
      setLoading(true);

      const { addressCoordinates } = formData;

      const imagesWithTypes = getImagesWithType(formData);
      const shouldUpdateImages = !!imagesWithTypes.length;

      let uploadedImages: UploadedImage[] = [];

      if (shouldUpdateImages) {
        const _uploadedImages = await uploadImages(imagesWithTypes);

        if (_uploadedImages) {
          uploadedImages = _uploadedImages;
        } else {
          return onError();
        }
      }

      return saveBusinessPreRegisterData({
        subscriptionId: formData.subscriptionId,
        customerName: formData.name,
        email: formData.email,
        phone: `${formData.countryCod}${formData.phone}`,
        businessName: formData.businessName,
        businessDescription: formData.description,
        businessRepresentativeId: formData.RUT,
        address: formData.address,
        coordinates: {
          latitude: addressCoordinates.lat,
          longitude: addressCoordinates.lng,
        },
        businessUrl: formData.contentURL,
        buttonActionText: formData.callToAction,
        keywords: formData.keywords.map(x => x.title),
        images: uploadedImages,
      })
        .then(() => {
          setLoading(false);
          goToSuccessView();
        })
        .catch(() => onError());
    },
    [goToSuccessView, onError, uploadImages],
  );

  return { submit, loading };
};

function getImagesWithType(images: Partial<FormData>): ImageWithType[] {
  const { businessBanner, businessLogo } = images;

  const _images: ImageWithType[] = [];

  if (businessBanner) {
    _images.push({
      file: businessBanner.file,
      dimension: businessBanner.dimension,
      type: BusinessImageType.Banner,
    });
  }

  if (businessLogo) {
    _images.push({
      file: businessLogo.file,
      dimension: businessLogo.dimension,
      type: BusinessImageType.Logo,
    });
  }

  return _images;
}

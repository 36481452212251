import { AUTH_KEY } from 'constants/env-variables';
import { useAppDispatch } from 'state';
import { authStateActions } from 'state/auth';

export const useInitializeAuthData = () => {
  const dispatch = useAppDispatch();
  const rawData = window.localStorage.getItem(AUTH_KEY) as string;

  try {
    const data = JSON.parse(rawData);

    if (data) {
      dispatch(authStateActions.setUser(data));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

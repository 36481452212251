import { FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styled from 'styled-components';

const BlockerLoading: FunctionComponent<StyledComponentProps> = ({
  className,
}) => {
  return createPortal(
    <div className={className}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 56 }} spin />} />
    </div>,
    document.body,
  );
};

export default styled(BlockerLoading)`
  background-color: rgba(0, 0, 0, 0.3);
  user-select: none;
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

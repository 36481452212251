import { useCallback, useEffect, useRef, useState } from 'react';
import getUserBusinesses from 'services/dashboard/get-user-businesses';

//Own
import { useAppSelector } from 'state';
import { UserLogged } from 'state/auth/types';

export const useBusinesses = (): Response => {
  const shouldMakeRequest = useRef(true);
  const user = useAppSelector(
    ({ authReducer }) => authReducer.user as UserLogged,
  );

  const [loading, setLoading] = useState(false);
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [hasError, setHasError] = useState(false);

  const fetchBusinesses = useCallback(async () => {
    setLoading(true);
    try {
      const business = await getUserBusinesses(user.accessToken);
      setBusinesses(business);
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [user?.accessToken]);

  useEffect(() => {
    if (shouldMakeRequest.current) fetchBusinesses();

    shouldMakeRequest.current = false;
  }, [fetchBusinesses]);

  return { businesses, loading, hasError };
};

export interface Business {
  uuid: string;
  name: string;
  avatarImageUrl: string;
  updatedAt: string;
  expiredAt: string | null;
}

export interface Response {
  businesses: Business[];
  loading: boolean;
  hasError: boolean;
}

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// Own
import { Plan } from '../types';
import { getTotalPrice, getRegularPrice, getDiscountRate } from './helpers';
import { LoadingOutlined } from '@ant-design/icons';

const Price: FunctionComponent<Props> = ({ className, plan, loading }) => {
  const { t } = useTranslation('plans');

  return (
    <div className={className}>
      {loading && (
        <div className="spinner-container">
          <LoadingOutlined className="spinner" />
        </div>
      )}
      {!loading && (
        <>
          <div className="regular-price">
            <span className="dollar-sign">$</span>{' '}
            <span>{getRegularPrice(plan)}</span>{' '}
            <span className="price-period">{t('pricePerPeriod')}</span>
          </div>
          {plan.saving && (
            <>
              <div className="total-price">
                <span className="dollar-sign">$</span> {getTotalPrice(plan)}{' '}
                {t('total')}
              </div>
              <div className="saving">
                {t('youSave')} {getDiscountRate(plan)}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

interface Props extends StyledComponentProps {
  plan: Plan;
  loading: boolean;
}

export default Price;

import styled from 'styled-components';
import Price from './price';

export default styled(Price)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .regular-price {
    text-align: center;
    font-size: 1.25rem;
    letter-spacing: -0.011em;
    color: #000000;
    font-weight: 800;

    .price-period {
      font-size: 1.25rem;
    }

    .dollar-sign {
      font-size: 1.25rem;
    }
  }

  .total-price {
    margin-top: 0.35rem;
    font-size: 0.8rem;
    color: #787878;
  }

  .saving {
    margin-top: 1.5rem;
    color: #f54286;
    font-size: 0.8rem;
    font-weight: 600;
  }

  &.recommended {
    .regular-price {
      font-size: 2rem;

      .dollar-sign {
        vertical-align: middle;
      }
    }
  }

  @keyframes spinnerRotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spinner-container {
    .spinner {
      animation: spinnerRotate 1s linear infinite;
      font-size: 1.875rem;
      color: #aeaeae;
    }
  }
`;

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

//Own
import { ReactComponent as AlertLogo } from 'assets/alert.svg';
import { CONTACT_EMAIL } from 'constants/env-variables';

const ErrorComponent: FunctionComponent<StyledComponentProps> = ({
  className,
}) => {
  const { t } = useTranslation('metrics-dashboard');

  return (
    <div className={className}>
      <div className="error__container">
        <AlertLogo />
        <p className="title__component">{[t('error.title')]}</p>
        <p className="description__component">{[t('error.description')]}</p>
        <a className="email__label" href={`mailto:${CONTACT_EMAIL}`}>
          {CONTACT_EMAIL}.
        </a>
      </div>
    </div>
  );
};

export default ErrorComponent;

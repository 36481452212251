//Own
import { API_V3 } from 'constants/env-variables';
import { Payload, Data } from './types';

async function getMetrics({
  businessUuid,
  endDate,
  startDate,
  timezoneGMT,
}: Payload): Promise<Data> {
  const url = `${API_V3}/business-ad-metrics/business/${businessUuid}/metrics`;

  const response = await fetch(
    `${url}?startDate=${startDate}&endDate=${endDate}&timezoneGmt=${timezoneGMT}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  ).then(response => response.json() as Promise<RawResponse>);

  if (!response.success || !response.data) {
    throw response;
  }

  return response.data;
}

export default getMetrics;

export interface RawResponse {
  success: boolean;
  data?: Data;
}

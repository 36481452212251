import PlacesInput from './index.component';
import styled from 'styled-components';

// Own
import { inputTextLarge } from 'styles/input-text';

export default styled(PlacesInput)`
  &.places-input-container {
    margin-bottom: 1.5rem;

    .places-input--error-message {
      color: red;
    }
  }

  &.autocomplete-place--dropdown-suggestion {
    .suggestion-item-active {
      background-color: #fafafa;
    }
  }

  @media (max-width: 800px) {
    &.autocomplete-place--input {
      ${inputTextLarge}
    }
  }
`;

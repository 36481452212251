export default {
  error: {
    title: 'No se puede cargar la página',
    description:
      'Ocurrió un error al cargar la página. Por favor vuelve a cargar el navegador, si el error persiste escribe a',
  },
  business: {
    dueDate: 'Fecha de vencimiento:',
    noDueDate: 'Sin fecha de vencimiento',
  },
  countSection: {
    click: 'Clics',
    impression: 'Impresiones',
    ctr: 'Tasa de clics (CTR)',
  },
  footer: {
    feedBackLabel: 'Déjanos tus comentarios',
    feedBackText:
      '¿Cómo podemos mejorar el tablero? Tu opinión es valiosa para nosotros.',
    formButton: 'Ir al formulario',
    website: 'Sitio web de SOSAFE',
    email: 'Correo electrónico de soporte',
  },
  sourceSection: {
    click: 'Clics por sección en la aplicación SOSAFE',
    impression: 'Impresiones por sección en la aplicación SOSAFE',
  },
  charts: {
    meta: {
      count: 'Cantidad',
    },
    actions: {
      click: 'Clics',
      impression: 'Impresiones',
    },
    sources: {
      comments: 'Comentarios en los reportes',
      'report-feed': 'Feed',
      map: 'Pin en el mercado',
      search: 'Busqueda en el mercado',
    },
  },
};

import { createContext, FunctionComponent, PropsWithChildren } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

// Own
import { GOOGLE_MAP_API_KEY } from 'constants/env-variables';

export const LoadMapContext = createContext({ isLoaded: false });

const LoadMapContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ['places'],
  });

  return (
    <LoadMapContext.Provider value={{ isLoaded }}>
      {children}
    </LoadMapContext.Provider>
  );
};

export default LoadMapContextProvider;

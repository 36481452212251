import { useCallback, useEffect, useState, useRef } from 'react';

import { Plan, ErrorType } from './types';
import getPlans from 'services/plans/get-plans';

export default function useGetPlans() {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | false>(false);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);

  const loadPlans = useCallback<LoadPlans>(async (coupon?: string) => {
    setLoading(true);
    setError(false);
    setCouponApplied(false);

    try {
      const response = await getPlans(coupon);

      if (response.success) {
        if (response.plans?.length) {
          setPlans(sortPlans(response.plans));

          setCouponApplied(!!response.couponApplied);

          // We got plans but not corresponding to the given coupon. Thus, invalid coupon.
          if (coupon && !response.plans[0].coupon) {
            setError(ErrorType.InvalidCode);
          }
        } else {
          setError(ErrorType.InvalidCode);
        }
      } else {
        setError(ErrorType.Unknown);
      }
    } catch {
      setError(ErrorType.Unknown);
    } finally {
      setLoading(false);
    }
  }, []);

  return { plans, couponApplied, loading, error, loadPlans };
}

export function useInitialPlans(loadPlans: LoadPlans, coupon?: string) {
  const initialLoadingDone = useRef(false);

  useEffect(() => {
    if (initialLoadingDone.current) return;

    initialLoadingDone.current = true;
    loadPlans(coupon);
  }, [coupon, loadPlans]);
}

export type LoadPlans = (coupon?: string) => Promise<void>;

function sortPlans(plans: Plan[]): Plan[] {
  const _plans = [...plans].sort((a, b) => +a.period - +b.period);

  const recommendedPlanIndex = _plans.findIndex(x => x.isRecommended);

  if (recommendedPlanIndex >= 0) {
    const recommendedPlan = _plans[recommendedPlanIndex];
    _plans.splice(recommendedPlanIndex, 1);

    const [firstPlan, ...otherPlans] = _plans;

    return [firstPlan, recommendedPlan, ...otherPlans];
  }

  return _plans;
}

import { FunctionComponent } from 'react';

//Own
import { ReactComponent as SosafeForBusinessLogo } from 'assets/sosafe-for-business.svg';
import { useGetMetricData } from './use-get-metric-data';
import ErrorComponent from './error-component';
import LoadingComponent from './loading-component';
import BodyComponent from './body-component';
import FooterComponent from './footer-component';

const MetricsDashboard: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  const { loading, hasError, data } = useGetMetricData();

  return (
    <div className={className}>
      <header className="header__container">
        <SosafeForBusinessLogo />
      </header>

      {loading && <LoadingComponent />}
      {hasError && <ErrorComponent />}

      {data && (
        <>
          <BodyComponent data={data} />
          <FooterComponent />
        </>
      )}
    </div>
  );
};

export default MetricsDashboard;

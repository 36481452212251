import {
  BUSINESS_BANNER_DIMENSION,
  BUSINESS_LOGO_DIMENSION,
} from 'constants/env-variables';

export const businessBannerDimension = formatDimensions(
  BUSINESS_BANNER_DIMENSION,
);
export const businessLogoDimension = formatDimensions(BUSINESS_LOGO_DIMENSION);

function formatDimensions(dims: number[]) {
  return dims.join('x');
}

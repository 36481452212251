import { Dimension } from './get-image-dimension';

export interface Image {
  file: File;
  dimension: Dimension;
}

export interface Props extends StyledComponentProps {
  required?: boolean;
  dimensions: string;
  mockupImageSrc: string;
  mockupClassName?: string;
  mockupInfo: string;
  label: string;
  onRemove: () => void;
  onUpload: (image: Image) => void;
}

export const MAX_FILES = 1 as const;
export const rawAllowedImageFormats = ['png', 'jpeg'] as const;
export const allowedImageMimeTypes = rawAllowedImageFormats.map(
  x => `image/${x}`,
);

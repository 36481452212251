import styled from 'styled-components';
import PlanCard from './index.component';

export default styled(PlanCard)`
  position: relative;

  .recommended-header {
    position: absolute;
    background-color: #f54286;
    color: white;
    line-height: 3rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: 700;
    left: 0;
    right: 0;
    top: -3rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .tag-icon {
      transform: scaleX(-1);
    }
  }

  .content {
    height: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    background-color: #fff;
    padding: 1.5rem;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    .name {
      text-align: center;
      font-size: 1.25rem;
      font-weight: 700;
    }

    .features {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .feature {
      display: flex;
      align-items: center;
      gap: 1rem;

      .check-icon {
        font-size: 1rem;
        color: #31ca78;
      }

      .feature-text {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }

    .btn-container {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
    }
  }

  &.recommended {
    .content {
      border-color: #f54286;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .feature {
        .check-icon {
          color: #f54286;
        }
      }
    }

    @media (max-width: 800px) {
      margin-top: 3rem;
    }
  }
`;

export default function uploadSignedImages({
  presignedUrl,
  mimeType,
  file,
}: Input): Promise<Response> {
  return fetch(presignedUrl, {
    headers: {
      'Content-Type': mimeType,
    },
    method: 'PUT',
    body: file,
  })
    .then(() => ({ success: true }))
    .catch(() => ({ success: false }));
}

interface Input {
  presignedUrl: string;
  mimeType: string;
  file: File;
}

interface Response {
  success: boolean;
}

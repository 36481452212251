import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

//Own
import authUserRequest from 'services/dashboard/auth-user';
import { useLogin } from 'core/guard-layout/use-login';

export const useAuthUser = (): Response => {
  const { tokenEmail } = useParams<{ tokenEmail: string }>();
  const shouldMakeRequest = useRef(true);

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const login = useLogin();
  const authUser = useCallback(async () => {
    setLoading(true);

    try {
      if (tokenEmail) {
        const data = await authUserRequest(tokenEmail);
        return login(data);
      }

      throw new Error('Invalid payload');
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [login, tokenEmail]);

  useEffect(() => {
    if (shouldMakeRequest.current) authUser();

    shouldMakeRequest.current = false;
  }, [authUser]);

  return { loading, hasError };
};

interface Response {
  loading: boolean;
  hasError: boolean;
}

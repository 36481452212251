import { useCallback } from 'react';

// Own
import getPresignedUrls, {
  PresignedUrl,
} from 'services/subscription/get-presigned-urls';
import uploadSignedImages from 'services/subscription/upload-signed-image';
import { Dimension } from './upload-image/get-image-dimension';
import { Image as UploadedImage } from 'services/subscription/pre-register/types';
import { BusinessImageType } from './types';

export const useUploadImages = () => {
  return useCallback(
    async (images: ImageWithType[]): Promise<UploadedImage[] | null> => {
      const imagesMimeType = images.map(x => x.file.type as string);

      const presignedUrls = await getPresignedUrls(imagesMimeType);

      if (!presignedUrls.success) return null;

      const _images = mergeImagesWithPresignedUrls(images, presignedUrls.urls);

      const uploadedImages = await Promise.all(
        _images.map(x =>
          uploadSignedImages({
            presignedUrl: x.presignedUrl.url,
            mimeType: x.file.type,
            file: x.file,
          }),
        ),
      )
        .then(response => response.every(x => x.success))
        .catch(() => false);

      if (!uploadedImages) return null;

      return _images.map(x => ({
        dimension: `${x.dimension.width}x${x.dimension.height}`,
        key: x.presignedUrl.key,
        type: x.type,
        url: x.presignedUrl.assetUrl,
      }));
    },
    [],
  );
};

function mergeImagesWithPresignedUrls(
  images: ImageWithType[],
  presignedUrls: PresignedUrl[],
) {
  return images.map((image, i) => ({
    ...image,
    presignedUrl: presignedUrls[i],
  }));
}

export interface ImageWithType {
  type: BusinessImageType;
  file: File;
  dimension: Dimension;
}

import styled from 'styled-components';
import UploadImage from './index.component';

export default styled(UploadImage)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 1.5rem;

  .upload-image {
    &--info {
      margin-bottom: 1.5rem;
    }

    &--mockup-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.5rem;
    }

    &--mockup-info {
      text-align: center;
      color: #787878;
      font-size: 0.75rem;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 1rem;

    .upload-image {
      &--mockup-container {
        align-items: center;
      }
    }
  }
`;

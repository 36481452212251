import { FunctionComponent } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import dayjs from 'dayjs';

//Own
import AntdConfigProvider from 'utils/antd-config-provider';
import router from './router';
import { store } from 'state';

require('dayjs/locale/es');
dayjs.locale('es');

const App: FunctionComponent = () => {
  return (
    <Provider store={store}>
      <AntdConfigProvider>
        <RouterProvider router={router} />
      </AntdConfigProvider>
    </Provider>
  );
};

export default App;

import { useCallback } from 'react';
import { EventType, logEvent } from 'utils/metrics/log-event';

export const useLogClickOnPayButton = () => {
  return useCallback((period: string) => {
    logEvent({
      event: EventType.ClickPayButton,
      props: {
        subscription_period: period,
      },
    });
  }, []);
};

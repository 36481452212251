import { API_V3 } from 'constants/env-variables';
import { Plan } from 'core/plans/types';

export default async function getPlans(coupon?: string): Promise<Response> {
  const queryParam = coupon ? `?coupon=${coupon}` : '';

  return fetch(`${API_V3}/business-markers/plans${queryParam}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  })
    .then(res => res.json())
    .catch(() => ({ success: false }));
}

export interface Response {
  success: boolean;
  plans?: Plan[];
  couponApplied?: boolean;
}

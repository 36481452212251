import { FunctionComponent } from 'react';

// Own
import bannerMobile from './banner-mobile.png';
import bannerDesktop from './banner-desktop.png';

const PromotionBanner: FunctionComponent<StyledComponentProps> = ({
  className,
}) => {
  return (
    <div className={className}>
      <img
        className="promotion-banner mobile"
        src={bannerMobile}
        alt="promotion banner"
      />
      <img
        className="promotion-banner desktop"
        src={bannerDesktop}
        alt="promotion banner"
      />
    </div>
  );
};

export default PromotionBanner;

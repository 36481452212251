import { FunctionComponent } from 'react';
import ReactMarkdown from 'markdown-to-jsx';

// Own
import { useMarkdown } from './use-markdown';
import rawTermsMarkdown from './terms.md';

const Terms: FunctionComponent<StyledComponentProps> = ({ className }) => {
  const termsMarkdown = useMarkdown(rawTermsMarkdown);

  return <ReactMarkdown className={className}>{termsMarkdown}</ReactMarkdown>;
};

export default Terms;

import { FunctionComponent } from 'react';

//Own
import { useAuthUser } from './use-auth-user';
import Loading from 'components/loading-component';
import Error from 'components/error-component';
import Header from 'components/header';

const AuthUserComponent: FunctionComponent = () => {
  const { loading, hasError } = useAuthUser();

  return (
    <div>
      <Header />
      {loading && <Loading />}
      {hasError && <Error />}
    </div>
  );
};

export default AuthUserComponent;

import { LANGUAGES } from 'constants/env-variables';
import names from './namespaces';

function buildNamespace(lang: string) {
  const result = {} as Record<(typeof names)[number], any>;

  for (const x of names) {
    result[x] = require(`locales/${lang}/${x}.ts`).default;
  }

  return result;
}

export const resources = {
  ...LANGUAGES.reduce((acc, lang) => {
    acc[lang] = buildNamespace(lang);
    return acc;
  }, {} as Record<string, any>),
};

export default {
  youSave: 'Ahorras un',
  goToPay: {
    default: 'Suscribirme',
    recommended: 'Contratar plan',
  },
  pricePerPeriod: 'por mes',
  total: 'total',
  apply: 'Aplicar',
  couponLabel: 'Código de descuento',
  couponLabelComment: '(Opcional)',
  loading: 'Cargando planes',
  errorLoading: 'Error al cargar planes',
  reload: 'Recargar',
  invalidCode: 'Código inválido',
  unknownError:
    'Hubo un error al intentar aplicar el código. Por favor reintenta.',
  couponApplied: '¡Descuento aplicado!',
};

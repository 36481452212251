import { useCallback, useEffect, useState } from 'react';

//Own
import { FIELDS_REQUIRE_GEOLOCATION, Field, fieldValidators } from './fields';

export const useValidateForm = (isGeolocated: boolean, getFields: Function) => {
  const [activeSubmit, setActiveSubmit] = useState(false);

  const validateRequiredFields = useCallback(() => {
    const fields = getFields();

    const _activeSubmit = Object.values(Field).every(field => {
      const value = fields[field];
      const validate = fieldValidators[field];

      if (FIELDS_REQUIRE_GEOLOCATION.includes(field))
        return isGeolocated ? validate(value) : true;

      return validate(value);
    });

    setActiveSubmit(_activeSubmit);
  }, [getFields, isGeolocated]);

  useEffect(() => {
    validateRequiredFields();
  }, [validateRequiredFields]);

  return { activeSubmit, validateRequiredFields };
};

import { FunctionComponent } from 'react';
import { useTranslation, Trans } from 'react-i18next';

//Own
import { CONTACT_EMAIL } from 'constants/env-variables';
import SubscriptionForm from 'core/subscription/form';
import LoadMapContextProvider from 'core/map/load-map-context';

const Subscription: FunctionComponent<
  StyledComponentProps & { isGeolocated: boolean }
> = ({ className, isGeolocated }) => {
  const { t } = useTranslation('subscription-page');

  return (
    <LoadMapContextProvider>
      <div className={className}>
        <h1 className="subscription-title">{t('title')}</h1>
        <p className="subscription-description">
          <Trans
            t={t}
            i18nKey={'description'}
            values={{ contactEmail: CONTACT_EMAIL }}
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                className="subscription-email"
                href={`mailto:${CONTACT_EMAIL}`}
              />,
            ]}
          />
        </p>
        <SubscriptionForm
          className="subscription-form"
          isGeolocated={isGeolocated}
        />
      </div>
    </LoadMapContextProvider>
  );
};

export default Subscription;

import styled from 'styled-components';

//Own
import LeftSideComponent from './left-side-component';

export default styled(LeftSideComponent)`
  width: 50%;
  background-color: white;
  padding: 136px 52px 46px 136px;
  min-width: 600px;

  input,
  button {
    z-index: 10 !important;
  }

  .title__label {
    margin-top: 50px;
    font-weight: 700;
    font-size: 20px;
    max-width: 500px;
  }

  .description__label {
    margin-top: 24px;
    font-weight: 400;
    font-size: 16px;
    color: #787878;
    max-width: 500px;
  }

  .form__container {
    max-width: 400px;
    margin-top: 24px;

    .email-input__label {
      font-size: 14px;
      font-weight: 700;
    }

    .email__input {
      width: 100%;
    }

    .submit__btn {
      width: 100%;
      margin-top: 20px;
    }
  }

  .alert__container {
    margin-top: 24px;
    max-width: 400px;
  }

  .header__container {
    padding-left: 0px;
  }

  @media only screen and (max-width: 576px) {
    width: unset;
    background-color: white;
    padding: 136px 52px 46px 52px;
    min-width: 350px;

    .title__label {
      margin-top: 50px;
      font-weight: 700;
      font-size: 20px;
      max-width: 100%;
    }

    .description__label {
      max-width: 100%;
    }

    .form__container {
      max-width: 100%;

      .email__input {
        max-width: 100%;
      }

      .submit__btn {
        max-width: 100%;
        margin-top: 20px;
      }
    }

    .alert__container {
      max-width: 100%;
    }

    .header__container {
      padding: 0px;

      .sfb__icon {
        width: 100%;
      }
    }
  }
`;

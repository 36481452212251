import styled from 'styled-components';
import PromotionBanner from './index.component';

export default styled(PromotionBanner)`
  .promotion-banner {
    display: block;
    width: 100%;
    height: auto;

    &.desktop {
      display: none;
    }
  }

  @media (min-width: 576px) {
    .promotion-banner {
      &.desktop {
        display: block;
      }
      &.mobile {
        display: none;
      }
    }
  }
`;

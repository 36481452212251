import { Form, Input, InputRef, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FunctionComponent, Ref, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';

// Own
import { useScrollToBottom } from './use-scroll-to-bottom';
import { Props, Keyword, KEYWORDS_SEPARATOR } from './types';

const Keywords: FunctionComponent<Props> = ({
  className,
  onChange,
  keywords,
}) => {
  const { t } = useTranslation('subscription-form');
  const inputRef = useRef<InputRef>(null);

  const { containerRef: keywordsContainerRef, scrollToBottom } =
    useScrollToBottom();

  const [inputValue, setInputValue] = useState('');

  const removeKeyword = (id: Keyword['id']) => {
    const newKeywords = keywords.filter(x => x.id !== id);

    onChange(newKeywords);
  };

  const setKeywordList = (keywordList: string[]) => {
    const newKeywords: Keyword[] = keywordList.map(x => ({
      id: generateId(),
      title: x,
    }));

    const _keywords = uniqBy([...keywords, ...newKeywords], 'title');

    onChange(_keywords);
    setInputValue('');
    scrollToBottom();
  };

  const onChangeInput = (value: string) => {
    if (value === KEYWORDS_SEPARATOR || value === ' ') return;

    if (!value.includes(KEYWORDS_SEPARATOR)) {
      setInputValue(value);
      return;
    }

    const keywordList = value
      .split(KEYWORDS_SEPARATOR)
      .map(x => x.trim())
      .filter(x => !!x);

    if (!!keywordList.length) {
      setKeywordList(keywordList);
    } else {
      setInputValue(value);
    }
  };

  const onClickContainer = () => {
    inputRef.current?.focus();
  };

  const placeholder = t('field.keywords.placeholder');

  return (
    <Form.Item
      label={t('field.keywords.label')}
      required
      tooltip={{
        title: t('field.keywords.info'),
        icon: <InfoCircleOutlined />,
      }}
    >
      <div
        ref={keywordsContainerRef}
        className={className}
        onClick={onClickContainer}
      >
        <div className="keywords--inner-container">
          {keywords.map(x => (
            <Tag
              className="keywords--tag"
              key={x.id}
              closable
              onClose={() => removeKeyword(x.id)}
            >
              {x.title}
            </Tag>
          ))}
          <Input
            className="keywords--input"
            ref={inputRef as Ref<InputRef>}
            bordered={false}
            placeholder={!keywords.length ? placeholder : ''}
            value={inputValue}
            onChange={e => onChangeInput(e.target.value)}
          />
        </div>
      </div>
    </Form.Item>
  );
};

function generateId() {
  return Math.floor(Math.random() * 100000);
}

export default Keywords;

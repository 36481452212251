import { FunctionComponent } from 'react';

//Own
import { useConfirmRegister } from './use-confirm-register';
import Loading from 'components/loading-component';
import Error from 'components/error-component';
import Header from 'components/header';

const ConfirmRegisterComponent: FunctionComponent = () => {
  const { loading, hasError } = useConfirmRegister();

  return (
    <div>
      <Header />
      {loading && <Loading />}
      {hasError && <Error />}
    </div>
  );
};

export default ConfirmRegisterComponent;

//Own
import { API_V3 } from 'constants/env-variables';
import { UserLogged } from 'state/auth/types';

async function confirmRegister(tokenEmail: string): Promise<UserLogged> {
  const url = `${API_V3}/business-markers/confirm-business/${tokenEmail}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(response => response.json() as Promise<RawResponse>);

  if (!response.success || !response.userLogged) {
    throw response;
  }

  return response.userLogged;
}

export default confirmRegister;

export interface RawResponse {
  success: boolean;
  userLogged?: UserLogged;
}
